import React, {useEffect, useState} from 'react'
import {array, func} from 'prop-types'
import Console from '../../utilities/ConsoleUtil'
import {localise} from '../../services/LocalizationServices'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import FormView from '../common/FormView'
import {useDispatch, useSelector} from 'react-redux'
import {
  getAddress,
  getAddressCountry,
  getAddressCountryCode,
  getAddressLine1,
  getAddressPostalCode,
  getAddressState,
  getDealershipUrl,
  getFacebookUrl,
  getInstagramUrl,
  // TODO: remove merchant key when testing complete
  getMerchantKey,
  getName,
  getSupportEmail,
  getTermsOfSale,
  getTownCity,
  getTradingName,
  getTwitterUrl,
  getYoutubeUrl,
  getRegistrationNumber,
  getPhoneNumber,
} from '../../store/selectors/settingsSelector'
import {getCountries} from '../../store/selectors/configurationSelectors'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {fetchSettings} from '../../api/settings/settings'
import {setSettings} from '../../store/slices/settingsSlice'
import Separator from '../../components/separator/Separator'
import {REACT_APP_ENVIRONMENT} from '../../constants/env'

const propTypes = {
  breadcrumbs: array,
  isDirty: func,
  onBlur: func,
  onCancel: func,
  onSubmit: func,
}

const defaultProps = {
  breadcrumbs: [],
  isDirty: dirty => Console.dev(dirty),
  onBlur: () => Console.dev('on blur'),
  onCancel: () => Console.dev('on cancel'),
  onSubmit: () => Console.dev('on submit'),
}

const COUNTRY_SELECT_ID = 'country-select'

const SettingsForm = ({breadcrumbs, isDirty, onBlur, onCancel, onSubmit}) => {
  const dispatch = useDispatch()

  const address = useSelector(getAddress)
  const addressLine1 = useSelector(getAddressLine1)
  const country = useSelector(getAddressCountry)
  const countries = useSelector(getCountries)
  const countryCode = useSelector(getAddressCountryCode)
  const county = useSelector(getAddressState)
  const dealershipUrl = useSelector(getDealershipUrl)
  const name = useSelector(getName)
  const postcode = useSelector(getAddressPostalCode)
  const supportEmail = useSelector(getSupportEmail)
  const termsOfSale = useSelector(getTermsOfSale)
  const townCity = useSelector(getTownCity)
  const tradingName = useSelector(getTradingName)
  const facebook = useSelector(getFacebookUrl)
  const twitter = useSelector(getTwitterUrl)
  const instagram = useSelector(getInstagramUrl)
  const youtube = useSelector(getYoutubeUrl)
  const registrationNumber = useSelector(getRegistrationNumber)
  const phoneNumber = useSelector(getPhoneNumber)
  // TODO: remove merchant key when testing complete
  const merchantKey = useSelector(getMerchantKey)

  const [countryOption, setCountryOption] = useState({})
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    toggleLoading()
    fetchSettings()
      .then(settings => dispatch(setSettings(settings)))
      .finally(toggleLoading)
  }, [dispatch])

  const COMMON_FORM_CLASSES = {
    additionalContainerClasses: 'mb-2',
    additionalLabelClasses: 'pt-2',
  }

  const dealershipFields = [
    {
      handler: name => dispatch(setSettings({name})),
      label: localise('form.label.name'),
      value: name || '',
      name: 'name',
      ...COMMON_FORM_CLASSES,
    },
    {
      handler: trading_name => dispatch(setSettings({trading_name})),
      label: localise('form.label.tradingName'),
      value: tradingName || '',
      name: 'tradingName',
      ...COMMON_FORM_CLASSES,
    },
    {
      handler: homepage_url => dispatch(setSettings({homepage_url})),
      label: localise('form.label.retailerUrl'),
      value: dealershipUrl || '',
      name: 'dealershipUrl',
      ...COMMON_FORM_CLASSES,
    },
    {
      handler: email => dispatch(setSettings({email})),
      label: localise('form.label.supportEmail'),
      value: supportEmail || '',
      name: 'supportEmail',
      ...COMMON_FORM_CLASSES,
    },
    {
      handler: phone_number => dispatch(setSettings({phone_number})),
      label: localise('form.label.phoneNumber'),
      value: phoneNumber || '',
      name: 'phoneNumber',
      ...COMMON_FORM_CLASSES,
    },
    {
      handler: registration_number => dispatch(setSettings({registration_number})),
      label: localise('form.label.registrationNumber'),
      value: registrationNumber || '',
      name: 'registrationNumber',
      ...COMMON_FORM_CLASSES,
    },
    {
      type: 'textarea',
      handler: terms_of_sale => dispatch(setSettings({terms_of_sale})),
      label: localise('form.label.termsOfSale'),
      value: termsOfSale || '',
      name: 'termsOfSale',
      ...COMMON_FORM_CLASSES,
      rows: 3,
    },
  ]

  const addressFields = [
    {
      handler: line1 => dispatch(setSettings({address: {...address, line1}})),
      label: localise('form.label.addressLine'),
      value: addressLine1 || '',
      name: 'addressLine1',
      ...COMMON_FORM_CLASSES,
    },
    {
      handler: city => dispatch(setSettings({address: {...address, city}})),
      label: localise('form.label.townCity'),
      value: townCity || '',
      name: 'townCity',
      ...COMMON_FORM_CLASSES,
    },
    {
      handler: state => dispatch(setSettings({address: {...address, state}})),
      label: localise('form.label.county'),
      value: county || '',
      name: 'county',
      ...COMMON_FORM_CLASSES,
    },
    {
      handler: postal_code => dispatch(setSettings({address: {...address, postal_code}})),
      label: localise('form.label.postcode'),
      value: postcode || '',
      name: 'postcode',
      ...COMMON_FORM_CLASSES,
    },
    {
      type: 'dropdown',
      id: COUNTRY_SELECT_ID,
      classNamePrefix: COUNTRY_SELECT_ID,
      name: 'country',
      label: localise('form.label.country'),
      placeholder: localise('form.placeholder.country'),
      options: countries?.map(({alpha2: value, name: label}) => ({value, label})),
      value: {value: countryOption.value || countryCode, label: countryOption.label || country},
      handler: country => {
        dispatch(setSettings({address: {...address, country_code: country?.value}}))
        setCountryOption(country)
      },
    },
  ]

  const socialFields = [
    {
      handler: facebook_url => dispatch(setSettings({facebook_url})),
      label: localise('form.label.facebook'),
      value: facebook || '',
      name: 'facebook',
      ...COMMON_FORM_CLASSES,
    },
    {
      handler: twitter_url => dispatch(setSettings({twitter_url})),
      label: localise('form.label.twitter'),
      value: twitter || '',
      name: 'twitter',
      ...COMMON_FORM_CLASSES,
    },
    {
      handler: youtube_url => dispatch(setSettings({youtube_url})),
      label: localise('form.label.youtube'),
      value: youtube || '',
      name: 'youtube',
      ...COMMON_FORM_CLASSES,
    },
    {
      handler: instagram_url => dispatch(setSettings({instagram_url})),
      label: localise('form.label.instagram'),
      value: instagram || '',
      name: 'instagram',
      ...COMMON_FORM_CLASSES,
    },
  ]

  const handleDirtyFields = fields => isDirty(Object.keys(fields).length !== 0)

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      onSubmit({
        name,
        trading_name: tradingName,
        terms_of_sale: termsOfSale,
        email: supportEmail,
        homepage_url: dealershipUrl,
        address: {
          line1: addressLine1,
          city: townCity,
          state: county,
          postal_code: postcode,
          country_code: countryCode,
        },
        facebook,
        twitter,
        instagram,
        youtube,
        registrationNumber,
        phoneNumber,
        // TODO: remove merchant key when testing complete
        merchant_key: merchantKey,
      })
      setValidated(true)
    }
  }

  return (
    <FormView
      breadcrumbs={breadcrumbs}
      className='mb-5'
      heading={localise('headings.retailerDetails')}
      onDirtyFieldsChanged={handleDirtyFields}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      submittable
      validated={validated}
      onBlur={onBlur}>
      {buildForm(dealershipFields)}
      <Separator className='mx-n3 my-3' />
      {buildForm(addressFields)}
      <Separator className='mx-n3 my-3' />
      {buildForm(socialFields)}
      {
        REACT_APP_ENVIRONMENT === 'development' &&
        buildForm([{
          type: 'input',
          handler: merchant_key => dispatch(setSettings({merchant_key})),
          label: 'Merchant Key',
          value: merchantKey || '',
          name: 'merchantKey',
          ...COMMON_FORM_CLASSES,
        }])
      }
    </FormView>
  )
}

SettingsForm.propTypes = propTypes
SettingsForm.defaultProps = defaultProps

export default SettingsForm
