import {createSlice} from '@reduxjs/toolkit'

const orderSlice = createSlice({
  name: 'order',
  initialState: {},
  reducers: {
    setOrder: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
  },
})

export const orderReducer = orderSlice.reducer
export const {setOrder} = orderSlice.actions
