import React, {useEffect, useState} from 'react'
import {Carousel as BSCarousel, Image} from 'react-bootstrap'
import {array, number, object, string} from 'prop-types'
import Skeleton from 'react-loading-skeleton'

const propTypes = {
  activeIndex: number.isRequired,
  carouselProps: object,
  carouselItemsProps: object,
  className: string,
  slides: array.isRequired,
}

const defaultProps = {
  activeIndex: 0,
  carouselProps: {},
  carouselItemsProps: {},
  className: '',
  slides: [{src: '', type: ''}],
}

const ACTIVE_SLIDE_ID = 'active-slide'

const Carousel = ({activeIndex, carouselProps, carouselItemsProps, className, slides}) => {
  const [selectedIndex, setSelectedIndex] = useState(activeIndex)

  useEffect(() => {
    setSelectedIndex(activeIndex)
  }, [activeIndex])

  useEffect(() => {
    pauseVideo()
  }, [selectedIndex])

  const pauseVideo = () => document?.querySelector('video#active-slide')?.pause()

  const toPreviousSlide = () => setSelectedIndex(selectedIndex === slides?.length - 1 ? 0 : selectedIndex + 1)

  const toNextSlide = () => setSelectedIndex(selectedIndex === 0 ? slides?.length - 1 : selectedIndex - 1)

  return (
    <div className={`carousel-container ml-lg-2 ${className}`}>
      <BSCarousel
        indicators={false}
        keyboard={false}
        nextIcon={<span
          aria-hidden='true'
          className='carousel-control-next-icon'
          onClick={toPreviousSlide}
        />}
        prevIcon={<span
          aria-hidden='true'
          className='carousel-control-prev-icon'
          onClick={toNextSlide}
        />}
        {...carouselProps}
        activeIndex={selectedIndex}>
        {
          slides.map(({src, type}, index) =>
            <BSCarousel.Item key={src} {...carouselItemsProps}>
              {
                type === 'image' &&
                <Image
                  alt=''
                  id={ACTIVE_SLIDE_ID}
                  src={src}
                />
              }
              {
                type === 'video' &&
                <video
                  autoPlay={selectedIndex === index}
                  className='cursor-pointer'
                  controls
                  key={src}
                  id={ACTIVE_SLIDE_ID}>
                  <source src={`${src}#t=2`} />
                  Your browser does not support the video tag.
                </video>
              }
              {
                type === 'skeleton' && <Skeleton count={1} className='img-active-skeleton' />
              }

            </BSCarousel.Item>
          )
        }
      </BSCarousel>
    </div>
  )
}

Carousel.propTypes = propTypes
Carousel.defaultProps = defaultProps

export default Carousel
