import React from 'react'
import {array, node, oneOf, string} from 'prop-types'
import {Dropdown} from 'react-bootstrap'
import UserInitial from '../user/UserInitial'
import styled from 'styled-components'
import {BRAND_PRIMARY} from '../../constants/colours'
import {useSelector} from 'react-redux'
import {getCurrentUserFirstName} from '../../store/selectors/currentUserSelectors'
import {DROPDOWN_ICON} from '../../constants/svgs'

const propTypes = {
  className: string,
  children: node,
  menu: array,
  menuAlignment: oneOf(['left', 'right']),
  menuClasses: string,
  toggleClasses: string,
  variant: string,
}

const defaultProps = {
  className: '',
  children: null,
  menu: [],
  menuAlignment: 'left',
  menuClasses: '',
  toggleClasses: '',
  variant: '',
}

const Toggle = styled(Dropdown.Toggle)`
  background-color: transparent !important;
  padding: 0;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  &:after { content: none }
`

const Menu = styled(Dropdown.Menu)`
  border-radius: 0;
  padding: 0;
  .dropdown-item {
    border-radius: 0;
    transition: background-color .3s, color .3s;
    &:hover,
    &.active {
      background-color: ${BRAND_PRIMARY};
      color: white !important;
    }
  }
`

const DropdownButton = ({className, children, menu, menuAlignment, menuClasses, toggleClasses, variant}) => {
  const name = useSelector(getCurrentUserFirstName)

  return (
    <Dropdown className={`dropdown-btn ${className}`} alignRight>
      {
        children
          ? <Dropdown.Toggle className={toggleClasses} variant={variant}>{children}</Dropdown.Toggle>
          : (
            <Toggle className={toggleClasses} variant={variant}>
              {
                name
                  ? <UserInitial name={name} />
                  : <img src={DROPDOWN_ICON} alt=''/>
              }
            </Toggle>
          )
      }
      <Menu align={menuAlignment} className={menuClasses}>
        {menu}
      </Menu>
    </Dropdown>
  )
}

DropdownButton.propTypes = propTypes
DropdownButton.defaultProps = defaultProps

export default DropdownButton
