export const getProductName = state => state?.product?.name
export const getProductDescription = state => state?.product?.description
export const getProductOverview = state => state?.product?.overview
export const getProductImages = state => state?.product?.images
export const getProductVideos = state => state?.product?.videos
export const getProductNameOne = state => state?.product?.nameOne
export const getProductDescriptionOne = state => state?.product?.descriptionOne
export const getProductNameTwo = state => state?.product?.nameTwo
export const getProductDescriptionTwo = state => state?.product?.descriptionTwo
export const getProductNameThree = state => state?.product?.nameThree
export const getProductDescriptionThree = state => state?.product?.descriptionThree
export const getProductActive = state => state?.product?.active
export const getProductDefaultToMaster = state => state?.product?.master_product
export const getProductUrl = state => state?.product?.url
export const getProductPrice = state => state?.product?.price
