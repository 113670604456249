import React from 'react'
import {string} from 'prop-types'
import styled from 'styled-components'
import '../../stylesheets/Colours.scss'
import {BRAND_PRIMARY} from '../../constants/colours'

const propTypes = {
  name: string,
}

const defaultProps = {
  name: 'User',
}

const Container = styled.div`
  background-color: ${BRAND_PRIMARY};
  width: 36px;
  height: 36px;
`

const Text = styled.div`
  color: white;
  position: relative;
  top: 8%;
  font-weight: 500;
  text-transform: capitalize;
`


const UserInitial = ({name}) => {
  return <Container className='user-initials-container rounded-circle'>
    <Text className='initials-text text-center font-20'>{name[0]}</Text>
  </Container>
}

UserInitial.propTypes = propTypes
UserInitial.defaultProps = defaultProps

export default UserInitial