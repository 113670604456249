import React, {useState} from 'react'
import {array, object, string} from 'prop-types'
import {Image} from 'react-bootstrap'
import Carousel from '../carousel/Carousel'
import Skeleton from 'react-loading-skeleton'
import {v4} from 'uuid'

const propTypes = {
  carouselItemsProps: object,
  carouselProps: object,
  className: string,
  media: array.isRequired,
}

const defaultProps = {
  carouselItemsProps: {},
  carouselProps: {},
  className: '',
  media: [],
}

const Gallery = ({carouselProps, carouselItemsProps, className, media}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const commonInactiveImgClasses = 'my-2 mt-lg-0'
  const commonInactiveImgMargins = 'mr-2 mr-lg-0'

  if (media?.length < 1) return <div />

  return (
    <div className={`d-flex flex-column flex-lg-row gallery-container ${className}`}>
      <div className={`d-flex flex-lg-column order-1 order-lg-0 image-list ${!media && 'skeleton-image-list'}`}>
        {
          (media || [{type: 'skeleton'}]).map((item, index) => {
            switch (item?.type) {
              case 'image':
                return (
                  <Image
                    className={`${commonInactiveImgClasses} ${index !== media.length - 1 && commonInactiveImgMargins} 
                    cursor-pointer img-inactive`}
                    key={item?.src}
                    onClick={() => setActiveImageIndex(media.indexOf(item))}
                    src={item?.src}
                  />
                )
              case 'video':
                return (
                  <video
                    className={`${commonInactiveImgClasses} ${index !== media.length - 1 && commonInactiveImgMargins} 
                    cursor-pointer img-inactive`}
                    key={item?.src}
                    onClick={() => setActiveImageIndex(media.indexOf(item))}>
                    <source src={`${item?.src}#t=3`} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                )
              case 'skeleton':
                return (
                  <Skeleton
                    count={4}
                    className={`${commonInactiveImgClasses} ${index !== media.length - 1 && commonInactiveImgMargins}
                       img-inactive-skeleton`}
                    key={v4()}
                  />
                )
              default:
                return null
            }
          })
        }
      </div>

      <div className='order-0 order-lg-1'>
        <Carousel
          {...carouselProps}
          {...carouselItemsProps}
          activeIndex={activeImageIndex}
          slides={media || [{type: 'skeleton'}]}
        />
      </div>
    </div>
  )
}

Gallery.propTypes = propTypes
Gallery.defaultProps = defaultProps

export default Gallery
