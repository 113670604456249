import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'
import getIndexParams from '../../utilities/api-helpers/getIndexParams'

export const fetchOrders = async opts => {
  const statuses = Object.keys(opts?.statuses).filter(key => opts?.statuses[key] === true).join(',') || ''
  const params = getIndexParams(opts)

  const {orders, meta: {total}} = await apiClient.get('/orders', {params: {...params, statuses}}).catch(handleError)
  return {orders, total}
}

const orderAction = async (action, url, data) => {
  const {order} = await apiClient[action](url, data).catch(handleError)
  return order
}

export const fetchOrder = async id => orderAction('get', `orders/${id}`)
export const updateOrder = async (id, order) => orderAction('put', `orders/${id}`, {order})

export const exportOrders = async () => {
  const {data, headers} = await apiClient.post('orders/exports').catch(handleError)
  return {data, headers}
}
