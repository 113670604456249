import React from 'react'
import Gallery from '../gallery/Gallery'
import Paragraph from '../paragraph/Paragraph'
import Separator from '../separator/Separator'
import Card from './Card'
import {array, string, shape} from 'prop-types'
import {localise} from '../../services/LocalizationServices'

const propTypes = {
  className: string,
  details: shape({
    textOne: string,
    textTwo: string,
    textThree: string,
    headingOne: string,
    headingTwo: string,
    headingThree: string,
  }),
  heading: string,
  media: array,
  overview: string,
  price: string,
  subheading: string,
}

const defaultProps = {
  className: '',
  details: null,
  heading: '',
  media: [],
  overview: '',
  price: '',
  subheading: '',
}

const ProductCard = ({className, details, heading, media, overview, price, subheading}) => {
  const containsStringValues = obj => Object.values(obj).some(item => typeof item === 'string' && item.length > 0)

  return (
    <Card className={`d-sm-flex flex-row flex-wrap flex-sm-nowrap p-3 mb-3 justify-content-center ${className}`}>
      {
        media.length > 0 &&
        <div className='d-flex d-lg-block justify-content-center mr-sm-2 mr-lg-5'>
          <Gallery media={media} />
        </div>
      }
      <div className={`${media.length > 0 && 'ml-md-5 pl-sm-4'} ml-0 flex-xl-grow-1 py-3 py-sm-0`}>
        <Paragraph heading={heading} headingClasses='font-20 font-bold'>
          <div>{subheading}</div>
          {price && <div className='font-15 font-bold'>{price}</div>}
        </Paragraph>
        <Separator className='border-bottom-dark pt-2 my-2' />
        {
          overview &&
            <>
              <Paragraph heading={localise('headings.overview')} className='py-2'>
                {overview}
              </Paragraph>
              {
                details && containsStringValues(details) && <Separator className='border-bottom-dark pt-2 mb-2' />
              }
            </>
        }
        {
          details &&
            <div className='pt-3'>
              {
                details?.headingOne && details?.textOne &&
                <Paragraph className='mb-2' heading={details?.headingOne}>
                  {details?.textOne}
                </Paragraph>
              }
              {
                details?.headingTwo && details?.textTwo &&
                <Paragraph className='mb-2' heading={details?.headingTwo}>
                  {details?.textTwo}
                </Paragraph>
              }
              {
                details?.headingThree && details?.textThree &&
                <Paragraph className='mb-2' heading={details?.headingThree}>
                  {details?.textThree}
                </Paragraph>
              }
            </div>
        }
      </div>
    </Card>
  )
}

ProductCard.propTypes = propTypes
ProductCard.defaultProps = defaultProps

export default ProductCard
