import React, {useEffect} from 'react'
import {Col, Row, Dropdown} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import {fetchOrder, updateOrder} from '../../api/order/order'
import {useParams} from 'react-router'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {ORDERS_URL} from '../../constants/urls'
import BreadCrumbsView from '../common/BreadcrumbsView'
import {setOrder} from '../../store/slices/orderSlice'
import {useDispatch, useSelector} from 'react-redux'
import CustomerDetails from './details/CustomerDetails'
import OrderDetails from './details/OrderDetails'
import {getOrderCode, getOrderStatus} from '../../store/selectors/orderSelectors'
import DropdownButton from '../../components/buttons/DropdownButton'
import useOrders from '../../hooks/useOrders'
import {toast} from 'react-hot-toast'
import {v4} from 'uuid'

const Order = () => {
  const {slug} = useParams()
  const dispatch = useDispatch()
  const {statuses: orderStatuses} = useOrders()

  const code = useSelector(getOrderCode)
  const status = useSelector(getOrderStatus)

  useEffect(() => {
    toggleLoading()
    fetchOrder(slug)
      .then(order => dispatch(setOrder(order)))
      .finally(toggleLoading)
  }, [dispatch, slug])

  const handleStatusChange = value => {
    toggleLoading()
    updateOrder(slug, {status: value})
      .then(order => {
        dispatch(setOrder(order))
        toast.success(localise('toast.orderUpdateSuccess'))
      })
      .finally(toggleLoading)
  }

  return (
    <BreadCrumbsView
      breadcrumbItems={[
        {
          active: false,
          href: ORDERS_URL,
          text: localise('nav.orders'),
        },
        {
          active: true,
          text: code || slug,
        },
      ]}
      buttons={<div className='d-flex justify-content-end'>
        <DropdownButton
          className='input-sm border-black'
          menu={orderStatuses?.map(({label, param: value}) =>
            <Dropdown.Item onClick={() => handleStatusChange(value, label)} key={v4()}>
              {label}
            </Dropdown.Item>
          )}>
          <span className='font-13 position-relative bottom-8 text-capitalize'>
            {orderStatuses.filter(({param}) => param === status)[0]?.label}
          </span>
        </DropdownButton>
      </div>}>
      <Row>
        <Col md='6' className='mb-3'>
          <OrderDetails />
        </Col>
        <Col md='6'>
          <CustomerDetails />
        </Col>
      </Row>
    </BreadCrumbsView>
  )
}

export default Order
