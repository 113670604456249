import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchSettings = async () => {
  const {settings} = await apiClient.get('settings').catch(handleError)
  return settings
}

export const updateSettings = async params => {
  const {settings} = await apiClient.put('settings', {settings: params}).catch(handleError)
  return settings
}
