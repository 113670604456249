import React from 'react'
import StatCard from '../../../components/cards/StatCard'
import {localise} from '../../../services/LocalizationServices'
import {Col, Image, Row} from 'react-bootstrap'
import Paragraph from '../../../components/paragraph/Paragraph'
import {useSelector} from 'react-redux'
import {
  getOrderCode,
  getOrderCreatedAt,
  getOrderItems,
  getOrderPriceItems,
  getOrderStatus,
} from '../../../store/selectors/orderSelectors'
import {v4} from 'uuid'
import {FALLBACK_IMAGE} from '../../../constants/svgs'
import useOrders from '../../../hooks/useOrders'

const OrderDetails = () => {
  const {statuses: orderStatuses} = useOrders()

  const orderCode = useSelector(getOrderCode)
  const date = useSelector(getOrderCreatedAt)
  const status = useSelector(getOrderStatus)
  const orderItems = useSelector(getOrderItems)
  const priceItems = useSelector(getOrderPriceItems)

  const codeAndDate = [
    {
      heading: localise('headings.orderCode'),
      value: orderCode,
    },
    {
      heading: localise('headings.date'),
      value: date,
    },
  ]

  const Section = ({data}) => (
    <Row>
      {
        data.map(({heading, value}) =>
          <Col key={v4()} sm='6'>
            <Paragraph heading={heading} className={heading === localise('headings.orderStatus') && 'text-capitalize'}>
              {value}
            </Paragraph>
          </Col>
        )
      }
    </Row>
  )

  const CodeAndDateSection = () => <Section data={codeAndDate} />
  const StatusSection = () => (
    <Section
      data={[
        {
          heading: localise('headings.orderStatus'),
          value: orderStatuses.filter(({param}) => param === status)[0]?.label,
        },
      ]}
    />
  )

  const Product = () => (
    <Row className='py-2'>
      {
        orderItems?.map(({product_variant: {product: {images, name, price}}}) => (
          <Col xs='12' className='d-flex my-2'>
            <Image
              className='object-fit-cover mr-2'
              onError={({currentTarget}) => currentTarget.src = FALLBACK_IMAGE}
              height={50}
              width={50}
              src={images[0]?.images?.small_url || FALLBACK_IMAGE}
            />
            <div className='flex-grow-1'>
              <div className='font-bold font-15'>{localise('headings.name')}</div>
              <div className='font-12'>{name}</div>
            </div>
            <div className='font-bold font-15 pr-2'>{price?.display}</div>
          </Col>
        )
        )
      }
    </Row>
  )

  const handleTotal = () => {
    const priceItem = priceItems?.find(item => item.type === 'total')
    if (priceItem) {
      return <div className='font-15 py-2 font-bold'>
        <div className='d-flex'>
          <div className='flex-grow-1'>{priceItem.title}</div>
          <div className='pr-2'>{priceItem.total.display}</div>
        </div>
      </div>
    }
    return null
  }

  // This might be needed in future
  const handleTotals = () =>
    priceItems?.map(({title, total: {display}, type}) => (
      <Row key={v4()} className={`font-15 py-2 ${type === 'total' && 'font-bold'}`}>
        <Col className='d-flex'>
          <div className='flex-grow-1'>{title}</div>
          <div className='pr-2'>{display}</div>
        </Col>
      </Row>
    ))

  return (
    <StatCard heading={localise('headings.orderDetails')}>
      <CodeAndDateSection />
      <StatusSection />
      <Product />
      {handleTotal()}
    </StatCard>
  )
}

export default OrderDetails
