import React, {useEffect, useState} from 'react'
import BreadCrumbsView from '../common/BreadcrumbsView'
import {CUSTOMERS_URL, ORDERS_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'
import {
  fetchCustomer, fetchCustomerOrders,
  updateCustomer,
} from '../../api/customer/customers'
import {useDispatch, useSelector} from 'react-redux'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {setCustomer} from '../../store/slices/customerSlice'
import {useParams} from 'react-router-dom'
import {
  getCustomerActive,
  getCustomerEmail,
  getCustomerFirstName,
  getCustomerLastName,
  getCustomerName,
  getCustomerPhoneNumber,
} from '../../store/selectors/customerSelectors'
import {Col, Dropdown} from 'react-bootstrap'
import DropdownButton from '../../components/buttons/DropdownButton'
import {v4} from 'uuid'
import Card from '../../components/cards/Card'
import Paragraph from '../../components/paragraph/Paragraph'
import Separator from '../../components/separator/Separator'
import OrdersTable from '../../components/tables/OrdersTable'
import {calculateOffset} from '../../components/pagination/Pagination'
import {useHistory} from 'react-router'

const Customer = () => {
  const [loading, setLoading] = useState(true)
  const [offset, setOffset] = useState(0)
  const [orders, setOrders] = useState([])
  const [query, setQuery] = useState('')
  const [total, setTotal] = useState()

  const dispatch = useDispatch()
  const {push} = useHistory()
  const {slug} = useParams()

  const name = useSelector(getCustomerName)
  const active = useSelector(getCustomerActive)
  const firstName = useSelector(getCustomerFirstName)
  const lastName = useSelector(getCustomerLastName)
  const email = useSelector(getCustomerEmail)
  const phoneNumber = useSelector(getCustomerPhoneNumber)

  useEffect(() => {
    toggleLoading()
    fetchCustomer(slug)
      .then(customer => dispatch(setCustomer(customer)))
      .finally(toggleLoading)
  }, [dispatch, slug])

  useEffect(() => {
    setLoading(true)
    fetchCustomerOrders(slug, {offset, query})
      .then(({orders, total}) => {
        setOrders(orders)
        setTotal(total)
      })
      .finally(() => setLoading(false))
  }, [query, offset, slug])

  const toOrder = id => push(`${ORDERS_URL}/${id}`)

  const updateStatus = () => {
    toggleLoading()
    updateCustomer(slug, {active: !active})
      .then(customer => dispatch(setCustomer(customer)))
      .finally(toggleLoading)
  }

  return (
    <BreadCrumbsView
      breadcrumbItems={[
        {
          active: false,
          href: CUSTOMERS_URL,
          text: localise('nav.customers'),
        },
        {
          active: true,
          text: name,
        },
      ]}
      buttons={(
        <div className='d-flex mt-2 mt-md-0 justify-content-end'>
          <DropdownButton
            toggleClasses='font-13'
            variant='outline'
            menuAlignment='right'
            menu={[
              <Dropdown.Item key={v4()} onClick={updateStatus}>
                {localise(`buttonText.${!active ? 'active' : 'inactive'}`)}
              </Dropdown.Item>,
            ]}>
            {localise(`buttonText.${active ? 'active' : 'inactive'}`)}
          </DropdownButton>
        </div>
      )}
      className='m-2 m-sm-3 d-flex'>
      <>
        <Card className='py-2 row mx-0 mb-4 shadow-none border'>
          <Col xs={12} className='font-bold font-15 mb-2'>
            {localise('headings.customerDetails')}
          </Col>
          <Col sm={6} className='border-bottom py-1'>
            <Paragraph heading={localise('headings.firstName')} children={firstName} />
          </Col>
          <Col sm={6} className='border-bottom py-1'>
            <Paragraph heading={localise('headings.lastName')} children={lastName} />
          </Col>
          <Col sm={6} className='py-1'>
            <Paragraph heading={localise('headings.email')} children={email} />
          </Col>
          <Separator className='w-100 px-0 mx-0 d-sm-none' />
          <Col sm={6} className='pt-2'>
            <Paragraph heading={localise('headings.phoneNumber')} children={phoneNumber} />
          </Col>
        </Card>
        <OrdersTable
          count={total}
          data={orders}
          hideName
          statusFilterEnabled={false}
          handleSearch={setQuery}
          loading={loading}
          onRowClick={toOrder}
          pagination={{
            onPageChange: page => setOffset(calculateOffset(page)),
            offset,
            total,
          }}
        />
      </>
    </BreadCrumbsView>
  )
}

export default Customer
