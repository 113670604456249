import React from 'react'
import Loader from 'react-loaders'
import BlockUi from 'react-block-ui'
import {BRAND_PRIMARY} from '../constants/colours'
import {useSelector} from 'react-redux'
import {isLoading} from '../store/selectors/loadingSelectors'
import 'react-block-ui/style.css'
import 'loaders.css/loaders.min.css'

const LoadingIndicator = () => {
  const isActive = useSelector(isLoading)
  const blockUIStyles = {position: 'absolute', width: '100vw', height: '100vh', zIndex: '2000'}
  const loaderStyles = {position: 'relative', left: '50%'}

  return (
    <BlockUi
      tag='div'
      style={blockUIStyles}
      hidden={!isActive}
      blocking={isActive}
      loader={(
        <Loader
          active={isActive}
          style={loaderStyles}
          type='ball-scale-ripple-multiple'
          color={BRAND_PRIMARY}
        />
      )}
    />
  )
}

export default LoadingIndicator
