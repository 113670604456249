import React from 'react'
import {bool, elementType, object, string, exact, arrayOf} from 'prop-types'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import {v4} from 'uuid'
import Console from '../../utilities/ConsoleUtil'

const propTypes = {
  breadcrumb: exact({
    as: elementType,
    bsPrefix: string,
    children: elementType,
    href: string,
    label: string,
    listProps: object,
  }),
  breadcrumbItems: arrayOf(
    exact({
      active: bool,
      as: elementType,
      bsPrefix: string,
      href: string,
      linkAs: elementType,
      linkProps: object,
      target: string,
      text: string,
      title: string,
    })
  ),
  className: string,
}

const defaultProps = {
  breadcrumb: {
    as: 'nav',
    bsPrefix: '',
    children: null,
    label: 'breadcrumb',
    listProps: {},
  },
  breadcrumbItems: [
    {
      active: false,
      as: 'li',
      bsPrefix: '',
      href: '#',
      linkProps: {onClick: () => Console.dev('linkProps.onClick')},
      target: null,
      text: 'breadcrumb 1',
      title: null,
    },
    {
      active: true,
      as: 'li',
      bsPrefix: '',
      href: '#',
      linkProps: null,
      target: null,
      text: 'breadcrumb 2',
      title: null,
    },
  ],
  className: '',
}

const BreadCrumbs = ({className, breadcrumb, breadcrumbItems}) => {
  const buildBreadCrumbsItems = () => (
    breadcrumbItems.map(breadcrumbItem => {
      const {bsPrefix, linkProps, text} = breadcrumbItem
      return (
        <React.Fragment key={v4()}>
          <Breadcrumb.Item {...breadcrumbItem} {...linkProps} bsPrefix={`breadcrumb-item ${bsPrefix}`}>
            {text}
          </Breadcrumb.Item>
        </React.Fragment>
      )
    })
  )

  return (
    <div className={`bread-crumb-container ${className}`}>
      <Breadcrumb {...breadcrumb} bsPrefix={`breadcrumb ${breadcrumb.bsPrefix}`}>
        {buildBreadCrumbsItems()}
      </Breadcrumb>
    </div>
  )
}

BreadCrumbs.propTypes = propTypes
BreadCrumbs.defaultProps = defaultProps

export default BreadCrumbs
