import React, {useCallback, useEffect, useState} from 'react'
import {array, func, number, string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'
import uploadMedia from '../../services/awsS3UploaderService'
import {BENTLEY_VIDEOS} from '../../constants/awsFolders'
import Dropzone from '../dropzone/Dropzone'
import handleError from '../../utilities/api-helpers/handleError'

const propTypes = {
  acceptedFileTypes: string,
  className: string,
  files: array,
  maxFiles: number,
  maxVideoByteSize: number,
  onChange: func,
  onLoading: func,
}

const defaultProps = {
  acceptedFileTypes: '.mp4',
  className: '',
  files: [],
  maxFiles: 1,
  maxVideoByteSize: 2000000000,
  onChange: files => Console.dev('onChange', {files}),
  onLoading: () => isLoading => Console.dev({isLoading}),
}

const VideoUploader = props => {
  const {
    acceptedFileTypes,
    className,
    files: currentFiles,
    maxFiles,
    maxVideoByteSize,
    onChange,
    onLoading,
  } = props

  const [files, setFiles] = useState(currentFiles)
  const [inputLoading, setInputLoading] = useState(false)

  useEffect(() => {
    onLoading(inputLoading)
  }, [inputLoading, onLoading])

  const amendFiles = useCallback(files => {
    setFiles(files)
    onChange(files)
  }, [onChange])

  const onDrop = async acceptedFiles => {
    setInputLoading(true)

    const uploadedFiles = await Promise.all(
      acceptedFiles.map(async file => {
        const url = await uploadMedia(file, BENTLEY_VIDEOS)
        return {url}
      })
    ).catch(handleError)

    amendFiles(uploadedFiles)

    setInputLoading(false)
  }

  useEffect(() => {
    setFiles(currentFiles)
  }, [currentFiles])

  const removeUpload = index => {
    amendFiles(files.filter((_, i) => i !== index))
    URL.revokeObjectURL(files[index].preview)
  }

  return (
    <Dropzone
      accept={acceptedFileTypes}
      className={className}
      createPreview
      data={files}
      loading={inputLoading}
      onDrop={onDrop}
      maxFiles={maxFiles}
      maxVideoByteSize={maxVideoByteSize}
      onRemoveClick={removeUpload}
      placeholderText={localise('form.placeholder.uploadVideo')}
    />
  )
}

VideoUploader.propTypes = propTypes
VideoUploader.defaultProps = defaultProps

export default VideoUploader
