import React from 'react'
import {Col, Image, Nav, Navbar, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {ROOT_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'
import {v4} from 'uuid'
import {COMPANY_LOGO_SMALL} from '../../constants/pngs'
import DropdownButton from '../buttons/DropdownButton'
import useNav from '../../hooks/useNav'

const TopNav = () => {
  const {
    collapseBreakpoint,
    isActiveLink,
    links,
  } = useNav()

  const handleLink = (link, linkClasses) => {
    if (!link) return

    if (link.button) {
      const Button = link.button
      return <Button key={v4()} className={`dropdown-item pl-4 py-2 pr-2 ${linkClasses}`} />
    }

    return (
      <Link key={v4()} className={`text-decoration-none ${linkClasses}`} to={link.to}>
        <div
          className={`dropdown-item font-black pl-4 py-2 pr-2 pl-${collapseBreakpoint}-0 ${
            isActiveLink(link.activeViews || []) ? 'active' : ''
          }`}>
          {localise(link.translationKey)}
        </div>
      </Link>
    )
  }

  const buildMobileMenu = () => {
    const classes = `d-${collapseBreakpoint}-none d-block`

    const navList = [
      links.dashboard,
      links.products,
      links.orders,
      links.customers,
      links.settings,
      links.logout,
    ]

    return navList.map(navItem => handleLink(navItem, classes))
  }

  const buildDesktopMenu = () => {
    const dropdownMenu = [links.logout].map(handleLink)

    return (
      <DropdownButton
        className={`d-${collapseBreakpoint}-block d-none mx-2`}
        menu={dropdownMenu}
      />
    )
  }

  return (
    <Row className={`top-nav-container bg-white py-${collapseBreakpoint}-1 py-0 border-bottom-primary-green`}>
      <Col xs='12' className='nav-wrapper'>
        <Navbar className={`bg-white p-${collapseBreakpoint}-3 p-0`} expand={collapseBreakpoint}>
          <Navbar.Brand className={`p-2 ml-4 d-${collapseBreakpoint}-none d-block`} href={ROOT_URL}>
            <Image src={COMPANY_LOGO_SMALL} alt={localise('alt.companyLogo')} />
          </Navbar.Brand>
          <Navbar.Toggle className='mr-4' aria-controls='top-navbar' />
          <Navbar.Collapse id='top-navbar'>
            <Nav className={`ml-auto mt-3 mt-${collapseBreakpoint}-0`}>
              {buildMobileMenu()}
              {buildDesktopMenu()}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Col>
    </Row>
  )
}

export default TopNav
