import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import {fetchProducts} from '../../api/product/product'
import {LIMIT} from '../../constants/api'
import {calculateOffset} from '../../components/pagination/Pagination'
import {useHistory} from 'react-router-dom'
import {PRODUCTS_URL, NEW_URL} from '../../constants/urls'
import ProductsTable from '../../components/tables/ProductsTable'
import ListView from '../common/ListView'

const Products = () => {
  const {push} = useHistory()

  const [products, setProducts] = useState([])
  const [total, setTotal] = useState()
  const [offset, setOffset] = useState(0)
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetchProducts({offset, query})
      .then(({products, total}) => {
        setTotal(total)
        setProducts(products)
      }).finally(() => setLoading(false))
  }, [offset, query])

  const toProduct = id => push(`${PRODUCTS_URL}/${id}`)
  const toProductCreate = () => push(`${PRODUCTS_URL}${NEW_URL}`)

  return (
    <ListView
      heading={localise('headings.products')}
      buttons={<>
        <Button variant='secondary' className='text-transform-none font-13' onClick={toProductCreate}>
          {localise('buttonText.createAProduct')}
        </Button>
      </>}>
      <ProductsTable
        data={products}
        count={total}
        handleSearch={setQuery}
        hideProductViews
        loading={loading}
        onRowClick={toProduct}
        pagination={{
          onPageChange: page => setOffset(calculateOffset(page, LIMIT)),
          total,
          offset,
        }}
      />
    </ListView>
  )
}

export default Products
