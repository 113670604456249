import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {localise} from '../../services/LocalizationServices'
import ProductForm from './ProductForm'
import {PRODUCTS_URL} from '../../constants/urls'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {fetchProduct, setMasterProduct, updateProduct} from '../../api/product/product'
import {toast} from 'react-hot-toast'
import {useDispatch, useSelector} from 'react-redux'
import {getProductDefaultToMaster, getProductName} from '../../store/selectors/productSelectors'
import {useParams} from 'react-router'
import {clearProduct, setProduct} from '../../store/slices/productSlice'
import Prompt from '../../components/modals/Prompt'
import {Button} from 'react-bootstrap'

const ProductEdit = () => {
  const dispatch = useDispatch()
  const {push, goBack} = useHistory()
  const {slug} = useParams()

  const [dirty, setDirty] = useState(false)
  const [submittable, setSubmittable] = useState(false)

  const productName = useSelector(getProductName)
  const productDefaultToMaster = useSelector(getProductDefaultToMaster)

  useEffect(() => {
    toggleLoading()
    fetchProduct(slug)
      .then(product => {
        if (product?.price?.amount) product.price.amount = (product.price.amount / 100).toFixed(2)
        dispatch(setProduct(product))
      })
      .finally(toggleLoading)

    return () => dispatch(clearProduct())
  }, [dispatch, slug])

  const toProduct = id => push(`${PRODUCTS_URL}/${id}`)

  const defaultToMaster = () => {
    toggleLoading()
    setMasterProduct(slug)
      .then(product => dispatch(setProduct(product)))
      .finally(toggleLoading)
  }

  const update = values => {
    toggleLoading()
    const {
      price,
      images,
      video,
      name,
      overview,
      description,
      productInformation,
    } = values

    const payload = {
      price: price?.amount,
      description,
      overview,
      name,
      product_information: productInformation,
      images,
      video,
    }

    updateProduct(slug, payload)
      .then(({id}) => {
        setDirty(false)
        toast.success(localise('toast.productUpdateSuccess'))
        toProduct(id)
      })
      .finally(toggleLoading)
  }

  return (
    <>
      <Prompt when={dirty} />
      <ProductForm
        buttons={(
          <div className='d-flex mt-2 mt-md-0 justify-content-md-end'>
            {
              !productDefaultToMaster ??
              <Button variant='green' className='font-13 mr-2' onClick={defaultToMaster}>
                {localise('buttonText.defaultToMaster')}
              </Button>
            }
            <Button variant='outline' className='font-13 mr-2' onClick={() => goBack()}>
              {localise('buttonText.cancel')}
            </Button>
            <Button type='submit' variant='secondary' className='font-13' disabled={!submittable}>
              {localise('buttonText.save')}
            </Button>
          </div>
        )}
        breadcrumbs={[
          {
            active: false,
            href: PRODUCTS_URL,
            text: localise('nav.products'),
          },
          {
            active: false,
            href: `${PRODUCTS_URL}/${slug}`,
            text: productName,
          },
          {
            active: true,
            text: localise('nav.edit'),
          },
        ]}
        isDirty={setDirty}
        isSubmittable={setSubmittable}
        onSubmit={update}
      />
    </>
  )
}

export default ProductEdit
