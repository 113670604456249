import React, {useEffect, useState} from 'react'
import {bool, func, number, string} from 'prop-types'
import styled from 'styled-components'
import {DARK, LIGHT_GREY} from '../../constants/colours'
import {localise} from '../../services/LocalizationServices'
import {Draggable} from 'react-beautiful-dnd'
import Skeleton from 'react-loading-skeleton'
import Console from '../../utilities/ConsoleUtil'
import {Video} from 'video-metadata-thumbnails'
import {FALLBACK_IMAGE} from '../../constants/svgs'

const propTypes = {
  className: string,
  createPreview: bool,
  draggable: bool,
  id: number,
  image: string,
  index: number,
  loading: bool,
  onRemoveClick: func,
  src: string,
}

const defaultProps = {
  className: '',
  createPreview: false,
  draggable: true,
  id: null,
  image: null,
  index: null,
  loading: false,
  onRemoveClick: () => Console.dev('on remove'),
  src: null,
}

const THUMBNAIL_SIZE = 101

const ThumbnailContainer = styled.div`
  height: ${THUMBNAIL_SIZE}px;
  width: ${THUMBNAIL_SIZE}px;
`

const RemoveIcon = styled.button`
  position: absolute;
  background-color: ${DARK};
  border-radius: 50%;
  color: white;
  height: 14px;
  width: 14px;
  cursor: pointer;
  border: none;

  &:hover,
  &:active { color: ${LIGHT_GREY} }

  &:after {
    content: '\\00D7';
    position: relative;
    bottom: 7px;
    right: 4px;
  }
`

const Thumbnail = ({className, createPreview, id, index, loading: thumbnailLoading, onRemoveClick, src}) => {
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(thumbnailLoading)

  useEffect(() => {
    (async () => {
      if (createPreview && src) {
        setLoading(true)
        const video = new Video(src)
        try {
          const response = await video.getThumbnails().catch(err => Console.dev(err, 'error'))
          setLoading(false)
          return setImage(URL.createObjectURL(response[0]?.blob))
        } catch(e) {
          Console.dev(e, 'error')
          setLoading(false)
          return setImage(FALLBACK_IMAGE)
        }
      }
      setImage(src)
    })()
  }, [createPreview, src])

  const getThumbnailStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? LIGHT_GREY : '#FFF',
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  return (
    loading
      ? <Skeleton height={THUMBNAIL_SIZE} width={THUMBNAIL_SIZE} className='my-auto mx-1' />
      : (
        <Draggable key={id} draggableId={`thumbnail-${id}`} index={index}>
          {
            (provided, snapshot) => (
              <ThumbnailContainer
                className={`border d-inline-flex mb-1 mr-2 p-1 ${className}`}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getThumbnailStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}>
                <div className='d-flex w-100 border-danger'>
                  <img
                    alt={localise('alt.thumbnail')}
                    className='w-100 object-fit-cover'
                    src={image}
                  />
                </div>
                <RemoveIcon type='button' onClick={e => {
                  e.stopPropagation()
                  onRemoveClick(index)
                }}
                />
              </ThumbnailContainer>
            )
          }
        </Draggable>
      )
  )
}

Thumbnail.propTypes = propTypes
Thumbnail.defaultProps = defaultProps

export default Thumbnail
