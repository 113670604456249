import React, {useEffect, useState} from 'react'
import {Row} from 'react-bootstrap'
import {array, func, node} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import FormView from '../common/FormView'
import Separator from '../../components/separator/Separator'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {useDispatch, useSelector} from 'react-redux'
import Console from '../../utilities/ConsoleUtil'
import {setProduct} from '../../store/slices/productSlice'
import useFileUploader from '../../hooks/useFileUploader'
import {
  getProductDescription,
  getProductDescriptionOne,
  getProductDescriptionTwo,
  getProductDescriptionThree,
  getProductImages,
  getProductName,
  getProductNameOne,
  getProductNameTwo,
  getProductNameThree,
  getProductOverview,
  getProductVideos,
  getProductPrice,
} from '../../store/selectors/productSelectors'
import {getCurrentUserCurrencySymbol} from '../../store/selectors/currentUserSelectors'

const propTypes = {
  breadcrumbs: array,
  buttons: node,
  isDirty: func,
  isSubmittable: func,
  onBlur: func,
  onCancel: func,
  onSubmit: func,
}

const defaultProps = {
  breadcrumbs: [],
  buttons: null,
  isDirty: dirty => Console.dev(dirty),
  isSubmittable: submittable => Console.dev(submittable),
  onBlur: () => Console.dev('on blur'),
  onCancel: () => Console.dev('on cancel'),
  onSubmit: () => Console.dev('on submit'),
}

const IMAGE_CRITERIA = {
  ACCEPTED_IMAGE_TYPES: '.jpg,.png',
  MAX_IMAGE_BYTE_SIZE: 2000000,
  MAX_FILES: 5,
}

const VIDEO_CRITERIA = {
  ACCEPTED_VIDEO_TYPES: '.mp4',
  MAX_VIDEO_BYTE_SIZE: 2000000000,
}

const ProductForm = ({breadcrumbs, buttons, isDirty, isSubmittable, onBlur, onCancel, onSubmit}) => {
  const dispatch = useDispatch()

  const {BILLION, MILLION} = useFileUploader({})
  const {ACCEPTED_IMAGE_TYPES, MAX_IMAGE_BYTE_SIZE, MAX_FILES} = IMAGE_CRITERIA

  const FIFTY = 50
  const FIFTEEN_HUNDRED = 1500

  const [filesUploading, setFilesUploading] = useState(false)
  const [validated, setValidated] = useState(false)

  const price = useSelector(getProductPrice) || ''
  const images = useSelector(getProductImages) || []
  const videos = useSelector(getProductVideos) || []
  const nameOne = useSelector(getProductNameOne) || ''
  const nameTwo = useSelector(getProductNameTwo) || ''
  const nameThree = useSelector(getProductNameThree) || ''
  const descriptionOne = useSelector(getProductDescriptionOne) || ''
  const descriptionTwo = useSelector(getProductDescriptionTwo) || ''
  const descriptionThree = useSelector(getProductDescriptionThree) || ''
  const name = useSelector(getProductName) || ''
  const description = useSelector(getProductDescription) || ''
  const overview = useSelector(getProductOverview) || ''
  const currentUserCurrencySymbol = useSelector(getCurrentUserCurrencySymbol) || ''

  const submittable =
    !filesUploading &&
    !!price &&
    !!name && name?.length < FIFTY &&
    !!description && description?.length < FIFTY &&
    !!overview && overview?.length < FIFTEEN_HUNDRED &&
    nameOne?.length < FIFTY &&
    descriptionOne?.length < FIFTY &&
    nameTwo?.length < FIFTY &&
    descriptionTwo?.length < FIFTY &&
    nameThree?.length < FIFTY &&
    descriptionThree?.length < FIFTY

  useEffect(() => {
    isSubmittable(submittable)
  }, [isSubmittable, submittable])

  const priceAndDetailFields = [
    {
      type: 'number',
      additionalContainerClasses: 'col-12',
      handler: amount => dispatch(setProduct({price: {...price, amount}})),
      isInvalid: validated && !price,
      label: `${localise('form.label.price')}*`,
      placeholder: localise('form.label.enterPrice'),
      prepend: price?.symbol || currentUserCurrencySymbol,
      required: true,
      validationMessage: localise('form.validation.price'),
      value: price?.amount,
    },
  ]

  const mediaFields = [
    {
      type: 'image-upload',
      acceptedFileTypes: ACCEPTED_IMAGE_TYPES,
      additionalContainerClasses: 'col-12',
      handler: files => dispatch(setProduct({images: files.map(({id, url}) => ({id, url}))})),
      label: localise('form.label.images'),
      maxFiles: MAX_FILES,
      maxImgByteSize: MAX_IMAGE_BYTE_SIZE,
      subLabel: localise('form.label.imageCriteria', {
        maxFiles: MAX_FILES,
        maxImgSize: `${MAX_IMAGE_BYTE_SIZE / MILLION}MB`,
      }),
      isInvalid: validated && images?.length < 1,
      onLoading: isLoading => setFilesUploading(isLoading),
      validationMessage: localise('form.validation.images'),
      files: images,
    },
    {
      type: 'video-upload',
      acceptedFileTypes: VIDEO_CRITERIA.ACCEPTED_VIDEO_TYPES,
      additionalContainerClasses: 'col-12',
      handler: videos => dispatch(setProduct({videos})),
      label: localise('form.label.video'),
      onLoading: isLoading => setFilesUploading(isLoading),
      subLabel: localise('form.label.videoCriteria', {
        maxVideoSize: `${VIDEO_CRITERIA.MAX_VIDEO_BYTE_SIZE / BILLION}GB`,
      }),
      files: videos,
    },
  ]

  const productDetailFields = [
    {
      additionalContainerClasses: 'col-12',
      handler: name => dispatch(setProduct({name})),
      label: `${localise('form.label.productName')}*`,
      placeholder: localise('form.label.productName'),
      isInvalid: name?.length >= FIFTY,
      required: true,
      subLabel: localise('form.label.maxChars', {maxCount: FIFTY}),
      validationMessage: name ?
        localise('form.validation.maxCharsExceeded') :
        localise('form.validation.productName'),
      value: name,
    },
    {
      additionalContainerClasses: 'col-12',
      handler: description => dispatch(setProduct({description})),
      label: `${localise('form.label.shortDescription')}*`,
      placeholder: localise('form.label.shortDescription'),
      isInvalid: description?.length >= FIFTY,
      required: true,
      subLabel: localise('form.label.maxChars', {maxCount: FIFTY}),
      validationMessage: description ?
        localise('form.validation.maxCharsExceeded') :
        localise('form.validation.productDescription'),
      value: description,
    },
    {
      type: 'textarea',
      additionalContainerClasses: 'col-12',
      handler: overview => dispatch(setProduct({overview})),
      isInvalid: overview?.length >= FIFTEEN_HUNDRED,
      label: `${localise('form.label.overview')}*`,
      placeholder: localise('form.label.overview'),
      maxLength: FIFTEEN_HUNDRED,
      required: true,
      subLabel: localise('form.label.maxChars', {maxCount: FIFTEEN_HUNDRED}),
      validationMessage: overview ?
        localise('form.validation.maxCharsExceeded') :
        localise('form.validation.productOverview'),
      value: overview,
    },
  ]

  const specFields = [
    {
      additionalContainerClasses: 'col-md-6',
      handler: nameOne => dispatch(setProduct({nameOne})),
      isInvalid: nameOne?.length >= FIFTY,
      label: localise('form.label.header', {headerNumber: 1}),
      subLabel: localise('form.label.maxChars', {maxCount: FIFTY}),
      validationMessage: localise('form.validation.maxCharsExceeded'),
      value: nameOne,
    },
    {
      additionalContainerClasses: 'col-md-6',
      handler: descriptionOne => dispatch(setProduct({descriptionOne})),
      isInvalid: descriptionOne?.length >= FIFTY,
      label: localise('form.label.information', {informationNumber: 1}),
      subLabel: localise('form.label.maxChars', {maxCount: FIFTY}),
      validationMessage: localise('form.validation.maxCharsExceeded'),
      value: descriptionOne,
    },
    {
      additionalContainerClasses: 'col-md-6',
      handler: nameTwo => dispatch(setProduct({nameTwo})),
      isInvalid: nameTwo?.length >= FIFTY,
      label: localise('form.label.header', {headerNumber: 2}),
      subLabel: localise('form.label.maxChars', {maxCount: FIFTY}),
      validationMessage: localise('form.validation.maxCharsExceeded'),
      value: nameTwo,
    },
    {
      additionalContainerClasses: 'col-md-6',
      handler: descriptionTwo => dispatch(setProduct({descriptionTwo})),
      isInvalid: descriptionTwo?.length >= FIFTY,
      label: localise('form.label.information', {informationNumber: 2}),
      subLabel: localise('form.label.maxChars', {maxCount: FIFTY}),
      validationMessage: localise('form.validation.maxCharsExceeded'),
      value: descriptionTwo,
    },
    {
      additionalContainerClasses: 'col-md-6',
      handler: nameThree => dispatch(setProduct({nameThree})),
      isInvalid: nameThree?.length >= FIFTY,
      label: localise('form.label.header', {headerNumber: 3}),
      subLabel: localise('form.label.maxChars', {maxCount: FIFTY}),
      validationMessage: localise('form.validation.maxCharsExceeded'),
      value: nameThree,
    },
    {
      additionalContainerClasses: 'col-md-6',
      handler: descriptionThree => dispatch(setProduct({descriptionThree})),
      isInvalid: descriptionThree?.length >= FIFTY,
      label: localise('form.label.information', {informationNumber: 3}),
      subLabel: localise('form.label.maxChars', {maxCount: FIFTY}),
      validationMessage: localise('form.validation.maxCharsExceeded'),
      value: descriptionThree,
    },
  ]

  const handleDirtyFields = fields => isDirty(Object.keys(fields).length !== 0)

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      let productInformation = [
        {
          name: nameOne,
          description: descriptionOne,
          sort_order: 1,
        },
        {
          name: nameTwo,
          description: descriptionTwo,
          sort_order: 2,
        },
        {
          name: nameThree,
          description: descriptionThree,
          sort_order: 3,
        },
      ]

      productInformation = productInformation.filter(({name, description}) =>
        (parseInt(name?.length) + parseInt(description?.length)) > 0)

      productInformation.map((item, index) => item.sort_order = index + 1)

      onSubmit({
        price,
        images,
        video: videos[0]?.url,
        name,
        overview,
        description,
        productInformation,
      })
    }
    setValidated(true)
  }

  return (
    <FormView
      breadcrumbs={breadcrumbs}
      buttons={buttons}
      className='mb-5'
      heading={localise('form.label.price')}
      onBlur={onBlur}
      onCancel={onCancel}
      onDirtyFieldsChanged={handleDirtyFields}
      onSubmit={handleSubmit}
      submittable={submittable}
      validated={validated}>
      <Row>
        {buildForm(priceAndDetailFields)}
        <Separator className='col-12 my-1' />
        <div className='col-12 font-bold font-17 py-2'>
          {localise('form.label.images')} / {localise('form.label.video')}
        </div>
        <Separator className='col-12 my-1' />
        {buildForm(mediaFields)}
        <Separator className='col-12 my-1' />
        <div className='col-12 font-bold font-17 py-2'>
          {localise('form.label.productDetails')}
        </div>
        <Separator className='col-12 my-1' />
        {buildForm(productDetailFields)}
        <Separator className='col-12 my-1' />
        <div className='col-12 font-bold font-17 py-1'>
          {localise('form.label.specs')}
        </div>
        <Separator className='col-12 mt-1 mb-3' />
        {buildForm(specFields)}
      </Row>
    </FormView>
  )
}

ProductForm.propTypes = propTypes
ProductForm.defaultProps = defaultProps

export default ProductForm
