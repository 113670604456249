import React from 'react'
import {object, string} from 'prop-types'
import 'chartkick/highcharts'
import {ColumnChart as ChartkickColumnChart} from 'react-chartkick'
import {BRAND_PRIMARY, GREY} from '../../constants/colours'
import styled from 'styled-components'

const propTypes = {
  chartProps: object,
  className: string,
  heading: string,
}

const defaultProps = {
  chartProps: {},
  className: '',
  heading: '',
}

const ColumnChartContainer = styled.div`max-width: 99%`

const ColumnChart = ({className, chartProps, heading}) =>
  <ColumnChartContainer className={`${className}`}>
    <div className='font-bold text-capitalize font-17'>
      {heading}
    </div>
    <ChartkickColumnChart
      library={{
        plotOptions: {
          column: {
            borderWidth: 0.1,
            color: BRAND_PRIMARY,
            groupPadding: 0.1,
            pointPadding: 0,
            pointWidth: 10,
            states: {
              hover: {
                borderColor: GREY,
                color: GREY,
              },
            },
          },
        },
        tooltip: {
          borderWidth: 0,
          formatter() {
            return `${this.x} <br/><b>${this.y}</b>`
          },
          hideDelay: 600,
          shadow: true,
          shape: 'rect',
        },
        yAxis: {
          visible: false,
        },
      }}
      thousands=','
      {...chartProps}
    />
  </ColumnChartContainer>

ColumnChart.propTypes = propTypes
ColumnChart.defaultProps = defaultProps

export default ColumnChart
