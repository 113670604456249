import React, {useState} from 'react'
import ReactPaginate from 'react-paginate'
import {bool, func, node, number, string} from 'prop-types'
import Console from '../../utilities/ConsoleUtil'
import {localise} from '../../services/LocalizationServices'
import {LIMIT} from '../../constants/api'

const propTypes = {
  activeClassName: string,
  activeLinkClassName: string,
  ariaLabelBuilder: func,
  breakClassName: string,
  breakLabel: node,
  breakLinkClassName: string,
  className: string,
  disabledClassName: string,
  disableInitialCallback: bool,
  displayStatus: bool,
  eventListener: string,
  forcePage: number,
  hrefBuilder: func,
  initialPage: number,
  limit: number,
  marginPagesDisplayed: number,
  nextAriaLabel: string,
  nextClassName: string,
  nextLabel: node,
  nextLinkClassName: string,
  offset: number,
  onPageChange: func,
  page: number,
  pageClassName: string,
  pageCount: number,
  pageLinkClassName: string,
  pageRangeDisplayed: number,
  previousAriaLabel: string,
  previousClassName: string,
  previousLabel: node,
  previousLinkClassName: string,
  total: number,
}

const defaultProps = {
  activeClassName: 'selected',
  activeLinkClassName: 'active-link',
  ariaLabelBuilder: () => {},
  breakLabel: '...',
  breakClassName: 'break',
  breakLinkClassName: 'break-link',
  className: '',
  disabledClassName: 'disabled',
  disableInitialCallback: false,
  displayStatus: true,
  eventListener: 'onClick',
  forcePage: 0,
  hrefBuilder: () => {},
  initialPage: 0,
  limit: LIMIT,
  marginPagesDisplayed: 1,
  nextAriaLabel: 'Next page',
  nextClassName: 'next',
  nextLabel: '',
  nextLinkClassName: 'next-link',
  offset: 0,
  onPageChange: pageNumber => Console.dev(`onPageChange, page => ${pageNumber}`),
  page: 1,
  pageClassName: 'page',
  pageCount: 10,
  pageLinkClassName: 'page-link',
  pageRangeDisplayed: 2,
  previousAriaLabel: 'Previous page',
  previousClassName: 'previous',
  previousLabel: '',
  previousLinkClassName: 'previous-link',
  total: 0,
}

const Pagination = props => {
  const [pageNumber, setPageNumber] = useState(0)
  const totalPageCount = Math.ceil(props?.pageCount)
  const {limit, offset, total} = props

  const showing = localise('tables.showing')
  const to = localise('tables.to')
  const of = localise('tables.of')

  const page = props?.page || offset + 1
  const upperLimit = limit + offset > total ? total : limit + offset

  return (
    <div className='d-sm-flex text-center align-items-center justify-content-between py-1'>
      {
        props?.displayStatus &&
        <div className='my-auto mr-auto mb-2 font-13 ml-2'>
          {`${showing} ${page} ${to} ${upperLimit} ${of} ${total}`}
        </div>
      }
      <ReactPaginate
        {...props}
        containerClassName={`pagination-container ${totalPageCount <= 1 && 'd-none'} ${props.className}`}
        nextClassName={`next ${totalPageCount === pageNumber && 'disabled'}`}
        nextLabel={props.nextLabel || localise('buttonText.next')}
        onPageChange={({selected}) => {
          const pageNumber = selected + 1
          setPageNumber(pageNumber)
          props.onPageChange(pageNumber)
        }}
        previousLabel={props.previousLabel || localise('buttonText.previous')}
      />
    </div>
  )
}

Pagination.propTypes = propTypes
Pagination.defaultProps = defaultProps

export default Pagination

export const calculateOffset = (pageNumber, limit = LIMIT) => {
  if (pageNumber === 1) return 0

  return (pageNumber - 1) * limit
}
