import {logout as revokeToken} from '../api/authentication/auth'
import {clearStore} from '../store/store'
import Console from '../utilities/ConsoleUtil'
import {useSelector} from 'react-redux'
import {getBentleySignOutRedirectUrl} from '../store/selectors/configurationSelectors'

const useAuth = () => {
  const signOutUrl = useSelector(getBentleySignOutRedirectUrl)

  const toSignOutUrl = () => signOutUrl ? window.location.href = signOutUrl : null

  const logout = async () => {
    try {
      await revokeToken()
      clearStore()
      return toSignOutUrl()
    }
    catch (e) {
      Console.dev(e, 'error')
      return e
    }
  }

  return {logout, signOutUrl, toSignOutUrl}
}

export default useAuth