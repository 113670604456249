import React, {useEffect, useState} from 'react'
import {array, bool, exact, func, number, string} from 'prop-types'
import Table from './Table'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'
import StatusBadge from '../badges/StatusBadge'
import SearchInput from '../inputs/SearchInput'
import DropdownButton from '../buttons/DropdownButton'
import CustomCheckbox from '../inputs/CustomCheckbox'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {DateTime} from 'luxon'
import {EUROPEAN_DATE_FORMAT} from '../../constants/dates'
import useOrders from '../../hooks/useOrders'

const propTypes = {
  className: string,
  count: number,
  data: array.isRequired,
  handleSearch: func,
  hideCount: bool,
  hideName: bool,
  hideStatus: bool,
  loading: bool,
  onRowClick: func,
  onStatusChange: func,
  pagination: exact({
    displayStatus: bool,
    className: string,
    onPageChange: func,
    pageCount: number,
    total: number,
    page: number,
    limit: number,
    offset: number,
  }),
  searchEnabled: bool,
  statusFilterEnabled: bool,
}

const defaultProps = {
  className: '',
  count: null,
  data: [],
  handleSearch: () => Console.dev('search changed'),
  hideCount: false,
  hideName: false,
  hideStatus: false,
  loading: false,
  onRowClick: () => Console.dev('row clicked'),
  onStatusChange: label => Console.dev({label}),
  pagination: null,
  searchEnabled: true,
  statusFilterEnabled: true,
}

const OrdersTable = props => {
  const {
    className,
    count,
    data,
    handleSearch,
    hideCount,
    hideName,
    hideStatus,
    loading,
    onRowClick,
    onStatusChange,
    pagination,
    searchEnabled,
    statusFilterEnabled,
  } = props

  const [defaultSearchValue, setDefaultSearchValue] = useState('')
  const {statuses: orderStatuses} = useOrders()
  const [statuses, setStatuses] = useState([])

  useEffect(() => {
    onStatusChange(statuses)
  }, [onStatusChange, statuses])

  const tableHeadings = [
    {text: localise('tables.name'), attributes: {className: hideName && 'd-none'}},
    localise('tables.product'),
    localise('tables.orderCode'),
    localise('tables.orderValue'),
    localise('tables.date'),
    {text: localise('tables.status'), attributes: {className: hideStatus && 'd-none'}},
  ]

  const tableBody = data?.map((
    {
      id,
      name,
      product,
      order_code: orderCode,
      order_value,
      created_at: date,
      display_status: status,
    }) => ({
    rowAttributes: {
      onClick: () => onRowClick(id),
      className: id && 'clickable',
    },
    row: [
      {
        cell: name,
        attributes: {className: hideName && 'd-none'},
      },
      {cell: product},
      {cell: orderCode},
      {cell: order_value?.display},
      {cell: DateTime.fromSeconds(date || 0).toFormat(EUROPEAN_DATE_FORMAT)},
      {
        cell: <StatusBadge active text={status} />,
        attributes: {className: `text-capitalize ${hideStatus && 'd-none'}`},
      },
    ],
  }))

  return (
    <Table
      className={`orders-table ${className}`}
      count={count}
      data={{tableHeadings, tableBody}}
      handleSearch={handleSearch}
      header={(
        <div className='py-2 pr-2 border-bottom d-flex flex-wrap'>
          <div className='row-count pl-2 flex-grow-1 font-17 text-nowrap mb-2 mb-sm-0'>
            <span className='mr-2 font-20 font-bold' hidden={hideCount}>{count || '0'}</span>
            <span className='title mr-2 text-truncate font-15'>{localise('tables.orders', {count})}</span>
          </div>
          <div className='d-flex'>
            {
              statusFilterEnabled &&
              <div className='my-auto text-nowrap'>
                <DropdownButton
                  className='input-sm border-black'
                  menu={buildForm(orderStatuses?.map(({param, label}) => {
                    return {
                      type: 'custom',
                      additionalContainerClasses: 'my-0 py-1 px-3',
                      render: (
                        <CustomCheckbox
                          label={label}
                          onChange={({target: {checked}}) => setStatuses({...statuses, [param]: checked, label})}
                        />
                      ),
                    }
                  }))}>
                  <span className='font-13 position-relative bottom-8'>{localise('tables.status')}</span>
                </DropdownButton>
              </div>
            }
            {
              searchEnabled && (
                <div className='my-auto'>
                  <SearchInput
                    defaultValue={defaultSearchValue}
                    handleSearch={value => {
                      setDefaultSearchValue(value)
                      handleSearch(value)
                    }}
                  />
                </div>
              )
            }
          </div>
        </div>
      )}
      loading={loading}
      pagination={pagination}
      skeletonProps={{count: data?.length}}
    />
  )
}

OrdersTable.propTypes = propTypes
OrdersTable.defaultProps = defaultProps

export default OrdersTable
