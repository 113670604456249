import {configureStore} from '@reduxjs/toolkit'
import reducers from './slices'
import {CLEAR_STATE} from '../constants/reduxTypes'

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware()
  },
})

export const clearStore = () => store.dispatch({type: CLEAR_STATE})

export default store
