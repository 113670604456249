import {REACT_APP_USE_REFRESH_TOKEN} from '../../constants/env'

export const BEARER_TOKEN = 'bearerToken'
const REFRESH_TOKEN = 'refreshToken'
const CURRENT_USER = 'currentUser'

export const setAuth = ({access_token, refresh_token}) => {
  localStorage.setItem(BEARER_TOKEN, access_token)
  localStorage.setItem(REFRESH_TOKEN, refresh_token)
}

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN)
export const getToken = () => localStorage.getItem(BEARER_TOKEN)
export const clearAuth = () => [BEARER_TOKEN, REFRESH_TOKEN, CURRENT_USER].map(id => localStorage.removeItem(id))
export const setUser = user => localStorage.setItem(CURRENT_USER, JSON.stringify(user))
export const useRefreshToken = () => REACT_APP_USE_REFRESH_TOKEN === 'ON'
