import React from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import store from '../store/store'
import {setupInterceptors} from '../api/client'
import Router from '../routes/Router'
import {Toaster} from 'react-hot-toast'
import Init from './Init'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../stylesheets/App.scss'
import LoadingIndicator from '../services/LoadingIndicator'

setupInterceptors()

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}>
        <LoadingIndicator />
        <Init />
        <Toaster
          position='top-center'
          reverseOrder={false}
        />
        <Router/>
      </BrowserRouter>
    </Provider>
  )
}

export default App
