import {createSlice} from '@reduxjs/toolkit'

const productSlice = createSlice({
  name: 'product',
  initialState: {},
  reducers: {
    setProduct: (state, action) => {
      if (action?.payload?.product_information?.length > 0) {
        action.payload.nameOne = action?.payload?.product_information[0].name
        action.payload.descriptionOne = action?.payload?.product_information[0].description

        if (action?.payload?.product_information[1]) {
          action.payload.nameTwo = action?.payload?.product_information[1].name
          action.payload.descriptionTwo = action?.payload?.product_information[1].description
        }

        if (action?.payload?.product_information[2]) {
          action.payload.nameThree = action?.payload?.product_information[2].name
          action.payload.descriptionThree = action?.payload?.product_information[2].description
        }
      }

      if (action?.payload?.images?.length > 0) {
        action?.payload?.images.forEach((image, index) => {
          if (action?.payload?.images[index]?.uploaded_url) {
            action.payload.images[index].url = action?.payload?.images[index]?.uploaded_url
          }
          else if (action?.payload?.images[index]?.images?.original_url) {
            action.payload.images[index].url = action?.payload?.images[index]?.images?.original_url
          }
        })
      }

      if (action?.payload?.videos?.original_url || action?.payload?.videos?.video_url) {
        action.payload.videos = [{url: action?.payload?.videos?.original_url || action?.payload?.videos?.video_url}]
      }

      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearProduct: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const productReducer = productSlice.reducer
export const {setProduct, clearProduct} = productSlice.actions
