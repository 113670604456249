import React, {useCallback, useEffect, useState} from 'react'
import {array, func, number, string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'
import uploadMedia from '../../services/awsS3UploaderService'
import {BENTLEY_IMAGES} from '../../constants/awsFolders'
import reorder from '../../utilities/list-helpers/reorder'
import Dropzone from '../dropzone/Dropzone'

const propTypes = {
  acceptedFileTypes: string,
  className: string,
  files: array,
  maxFiles: number,
  maxImgByteSize: number,
  onChange: func,
  onLoading: func,
}

const defaultProps = {
  acceptedFileTypes: '',
  className: '',
  files: [],
  maxFiles: 5,
  maxImgByteSize: 20000000,
  onChange: files => Console.dev('onChange', {files}),
  onLoading: isLoading => Console.dev({isLoading}),
}

const ImageUploader = props => {
  const {
    acceptedFileTypes,
    className,
    files: currentFiles,
    maxFiles,
    maxImgByteSize,
    onChange,
    onLoading,
  } = props

  const [files, setFiles] = useState([])
  const [inputLoading, setInputLoading] = useState(false)

  useEffect(() => {
    setFiles(currentFiles)
  }, [currentFiles])

  useEffect(() => {
    onLoading(inputLoading)
  }, [inputLoading, onLoading])

  const amendFiles = useCallback(files => {
    setFiles(files)
    onChange(files)
  }, [onChange])

  const onDrop = acceptedFiles => {
    setInputLoading(true)
    Promise.all(acceptedFiles.map(async file => {
      const url = await uploadMedia(file, BENTLEY_IMAGES)
      return {name: file.name, url, size: file.size, type: file.type}
    }))
      .then(uploadedFiles => {
        if (files?.length + uploadedFiles?.length <= maxFiles) amendFiles([...files, ...uploadedFiles])
      })
      .finally(() => setInputLoading(false))
  }

  const onDragEnd = useCallback(result => {
    // dropped outside the list
    if (!result.destination) return

    setInputLoading(true)
    setTimeout(() => {
      try {
        const data = reorder(
          files,
          result.source.index,
          result.destination.index
        )

        amendFiles(data)
      } catch (e) {
        Console.dev(e, 'error')
      } finally {
        setInputLoading(false)
      }
    }, 200)
  }, [amendFiles, files])

  const removeUpload = index => {
    setInputLoading(true)
    amendFiles(files.filter((_, i) => i !== index))
    URL.revokeObjectURL(files[index].url)
    // This masks the delay on the image list
    setTimeout(() => setInputLoading(false), 200)
  }

  return (
    <Dropzone
      className={className}
      data={files}
      accept={acceptedFileTypes}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
      loading={inputLoading}
      maxFiles={maxFiles}
      maxImgByteSize={maxImgByteSize}
      placeholderText={localise('form.placeholder.uploadImage')}
      onRemoveClick={removeUpload}
    />
  )
}

ImageUploader.propTypes = propTypes
ImageUploader.defaultProps = defaultProps

export default ImageUploader
