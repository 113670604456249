import React from 'react'
import {array, bool, exact, func, number, string} from 'prop-types'
import Table from './Table'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'

const propTypes = {
  className: string,
  count: number,
  data: array.isRequired,
  handleSearch: func,
  loading: bool,
  onRowClick: func,
  pagination: exact({
    displayStatus: bool,
    className: string,
    onPageChange: func,
    pageCount: number,
    total: number,
    page: number,
    limit: number,
    offset: number,
  }),
}

const defaultProps = {
  className: '',
  count: null,
  data: [],
  handleSearch: () => Console.dev('search changed'),
  loading: false,
  onRowClick: () => Console.dev('row clicked'),
  pagination: null,
}

const CustomersTable = props => {
  const {
    className,
    count,
    data,
    handleSearch,
    loading,
    onRowClick,
    pagination,
  } = props

  const tableHeadings = [
    localise('tables.firstName'),
    localise('tables.lastName'),
    localise('tables.email'),
    localise('tables.phone'),
  ]

  const tableBody = data?.map((
    {
      id,
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phone,
    }) => ({
    rowAttributes: {
      onClick: () => onRowClick(id),
      className: id && 'clickable',
    },
    row: [
      {cell: firstName},
      {cell: lastName},
      {cell: email},
      {cell: phone},
    ],
  }))

  return (
    <Table
      title={localise('tables.customers', {count})}
      className={`customers-table ${className}`}
      data={{tableHeadings, tableBody}}
      count={count}
      pagination={pagination}
      skeletonProps={{count: data?.length}}
      loading={loading}
      handleSearch={handleSearch}
    />
  )
}

CustomersTable.propTypes = propTypes
CustomersTable.defaultProps = defaultProps

export default CustomersTable
