import React, {useState} from 'react'
import {string} from 'prop-types'
import {Button} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import Modal from '../modals/Modal'
import useAuth from '../../hooks/useAuth'

const propTypes = {className: string}
const defaultProps = {className: ''}

const LogoutButton = ({className}) => {
  const {logout} = useAuth()

  const [show, setShow] = useState(false)

  const handleLogout = () => {
    setShow(false)
    toggleLoading()
    logout().finally(toggleLoading)
  }

  const hide = () => setShow(false)

  return (
    <>
      <Button
        variant='link'
        className={`font-dark ${className}`}
        onClick={() => setShow(true)}>
        {localise('buttonText.logout')}
      </Button>
      <Modal
        show={show}
        toggle={hide}
        headerText={localise('modalText.logOut')}
        bodyElement={localise('modalText.logOutMessage')}
        bodyClasses='border py-2'
        additionalBodyClasses='font-15 pt-2 pb-4'
        footerElement={<>
          <Button variant='outline' className='font-12 font-bold py-1 rounded mr-2' onClick={hide}>
            {localise('buttonText.cancel')}
          </Button>
          <Button variant='secondary' className='font-12 font-bold py-1 rounded' onClick={handleLogout}>
            {localise('buttonText.confirm')}
          </Button>
        </>}
      />
    </>
  )
}

LogoutButton.propTypes = propTypes
LogoutButton.defaultProps = defaultProps

export default LogoutButton
