import {createSlice} from '@reduxjs/toolkit'

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {},
  reducers: {
    setSettings: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
  },
})

export const settingsReducer = settingsSlice.reducer
export const {setSettings} = settingsSlice.actions
