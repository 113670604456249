import React from 'react'
import {string} from 'prop-types'

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

const Separator = ({className}) => <div className={`border-bottom ${className}`} />

Separator.propTypes = propTypes
Separator.defaultProps = defaultProps

export default Separator