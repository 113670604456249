import React from 'react'
import {func, string} from 'prop-types'
import Modal from './Modal'
import {Button} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'

const propTypes = {
  hide: func,
  name: string.isRequired,
  onConfirm: func,
}

const defaultProps = {
  hide: () => {},
  name: '',
  onConfirm: () => {},
}

const DeleteModal = props => {
  const {name, hide, onConfirm, ...rest} = props
  return (
    <Modal
      {...rest}
      bodyClasses='p-3'
      bodyText={localise('modalText.deleteConfirm', {name: name.toLowerCase()})}
      className='modal-delete'
      footerElement={<>
        <Button variant='outline-secondary' className='py-1 px-2 font-13 mr-2 rounded' onClick={hide}>
          {localise('buttonText.cancel')}
        </Button>
        <Button variant='secondary' className='py-1 px-2 font-13 rounded' onClick={onConfirm}>
          {localise('buttonText.confirm')}
        </Button>
      </>}
      headerClasses='text-capitalize'
      headerText={localise('modalText.deleteHeading', {name})}
      toggle={hide}>
    </Modal>
  )
}

DeleteModal.propTypes = propTypes
DeleteModal.defaultProps = defaultProps

export default DeleteModal
