import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {getBentleySignInUrl} from '../../store/selectors/configurationSelectors'
import {useHistory} from 'react-router'
import {DASHBOARD_URL} from '../../constants/urls'
import useAuth from '../../hooks/useAuth'

const Auth = () => {
  const {push} = useHistory()
  const {toSignOutUrl} = useAuth()

  const bentleySignInUrl = useSelector(getBentleySignInUrl)

  const validUrl = () => (
    window.location.search.indexOf('CONTEXT_CHANGE') !== -1 &&
    window.location.search.indexOf('Target') !== -1 &&
    window.location.search.indexOf('CONTRACT_KEY') !== -1 &&
    window.location.search.indexOf('CONTRACT_TYPE') !== -1 &&
    window.location.search.indexOf('DEALER') !== -1
  )

  const toSignIn = () => {
    const search = window.location.search
    const redirect = `%26redirect_to=${window.location.origin}`

    window.location.href = `${bentleySignInUrl}${search}${redirect}`
  }

  useEffect(() => {
    if (!window.location.search) return push(DASHBOARD_URL)
    if (bentleySignInUrl && validUrl()) {
      toSignIn()
    } else {
      toSignOutUrl()
    }
    //eslint-disable-next-line
  }, [bentleySignInUrl])

  return null
}

export default Auth