import React, {useEffect, useRef, useState} from 'react'
import {array, bool, func, node, string} from 'prop-types'
import BreadCrumbsView from './BreadcrumbsView'
import Console from '../../utilities/ConsoleUtil'
import {Button, Form} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'

const propTypes = {
  breadcrumbs: array,
  buttons: node,
  children: node,
  className: string,
  heading: string,
  onBlur: func,
  onCancel: func,
  onDirtyFieldsChanged: func,
  onSubmit: func,
  submittable: bool,
  validated: bool,
}

const defaultProps = {
  breadcrumbs: [],
  buttons: null,
  children: null,
  className: '',
  heading: '',
  onBlur: () => Console.dev('on blur'),
  onCancel: () => Console.dev('on cancel'),
  onDirtyFieldsChanged: () => Console.dev('dirty fields changed'),
  onSubmit: () => Console.dev('on submit'),
  submittable: false,
  validated: false,
}

const FormView = props => {
  const form = useRef()

  const {
    breadcrumbs,
    buttons,
    children,
    className,
    heading,
    onBlur,
    onCancel,
    onDirtyFieldsChanged,
    onSubmit,
    submittable,
    validated,
  } = props

  const [dirtyFields, setDirtyFields] = useState({})
  const [initialValues, setInitialValues] = useState({})

  const onChange = e => {
    const {name, value} = e.target

    if (value !== initialValues[name]) {
      setDirtyFields({...dirtyFields, [name]: value})
    } else {
      delete dirtyFields[name]
      setDirtyFields(dirtyFields)
    }
  }

  useEffect(() => {
    onDirtyFieldsChanged(dirtyFields)
  }, [onDirtyFieldsChanged, dirtyFields])

  useEffect(() => {
    const thisForm = form?.current

    if (thisForm) {
      const inputs = thisForm?.getElementsByTagName('input')
      if (inputs) {
        const initialValues = {}
        for (let input of inputs) {
          initialValues[input.name] = input.value === undefined || input.value === null ? '' : input.value
        }
        setInitialValues(initialValues)
      }
    }
  }, [form])

  return (
    <Form ref={form} noValidate validated={validated} onSubmit={onSubmit} onBlur={onBlur} onChange={onChange}>
      <BreadCrumbsView
        className={className}
        breadcrumbItems={breadcrumbs}
        onCancel={onCancel}
        buttons={buttons ||
          (
            <div className='d-flex mt-2 mt-md-0'>
              <Button variant='outline' className='font-13 mr-2 ml-md-auto' onClick={onCancel}>
                {localise('buttonText.cancel')}
              </Button>
              <Button type='submit' variant='secondary' className='font-13' disabled={!submittable}>
                {localise('buttonText.save')}
              </Button>
            </div>
          )}>
        <div className='border rounded'>
          <div className='font-17 font-bold border-bottom p-3'>{heading}</div>
          <div className='p-3'>{children}</div>
        </div>
      </BreadCrumbsView>
    </Form>
  )
}

FormView.propTypes = propTypes
FormView.defaultProps = defaultProps

export default FormView