import React from 'react'
import {Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import Settings from '../views/settings/Settings'
import SettingsEdit from '../views/settings/SettingsEdit'

const SettingRoutes = ({path}) =>
  <Switch>
    <RouteWithLayout exact path={path} component={Settings} />
    <RouteWithLayout exact path={`${path}/:edit`} component={SettingsEdit} />
  </Switch>

export default SettingRoutes
