import React from 'react'
import {Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import {EDIT_URL, NEW_URL} from '../constants/urls'
import Product from '../views/product/Product'
import Products from '../views/product/Products'
import ProductCreate from '../views/product/ProductCreate'
import ProductEdit from '../views/product/ProductEdit'

const ProductRoutes = ({path}) =>
  <Switch>
    <RouteWithLayout exact path={path} component={Products} />
    <RouteWithLayout exact path={`${path}${NEW_URL}`} component={ProductCreate} />
    <RouteWithLayout exact path={`${path}/:slug`} component={Product} />
    <RouteWithLayout exact path={`${path}/:slug${EDIT_URL}`} component={ProductEdit} />
  </Switch>

export default ProductRoutes
