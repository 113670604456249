import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {
  AUTH_URL,
  CODE_URL,
  CUSTOMERS_URL,
  DASHBOARD_URL,
  ORDERS_URL,
  PRODUCTS_URL,
  ROOT_URL,
  SETTINGS_URL,
} from '../constants/urls'
import RouteWithLayout from './RouteWithLayout'
import ProductRoutes from './ProductRoutes'
import OrdersRoutes from './OrdersRoutes'
import CustomerRoutes from './CustomerRoutes'
import SettingRoutes from './SettingsRoutes'
import Dashboard from '../views/dashboard/Dashboard'
import AuthCode from '../views/auth/AuthCode'
import Auth from '../views/auth/Auth'

const AppRoute = () => {
  return (
    <Switch>
      <Route exact path={ROOT_URL}><Auth /></Route>
      <Route exact path={`${AUTH_URL}${CODE_URL}`} component={AuthCode} />
      <RouteWithLayout exact path={DASHBOARD_URL} component={Dashboard} />
      <ProductRoutes path={PRODUCTS_URL} />
      <OrdersRoutes path={ORDERS_URL} />
      <CustomerRoutes path={CUSTOMERS_URL} />
      <SettingRoutes path={SETTINGS_URL} />
    </Switch>
  )
}

export default AppRoute
