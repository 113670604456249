import React from 'react'
import {Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import Orders from '../views/orders/Orders'
import Order from '../views/orders/Order'

const OrderRoutes = ({path}) =>
  <Switch>
    <RouteWithLayout exact path={path} component={Orders} />
    <RouteWithLayout exact path={`${path}/:slug`} component={Order} />
  </Switch>

export default OrderRoutes
