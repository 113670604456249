export const AUTH_URL = '/auth'
export const CODE_URL = '/code'
export const CUSTOMERS_URL = '/customers'
export const DASHBOARD_URL = '/dashboard'
export const EDIT_URL = '/edit'
export const NEW_URL = '/new'
export const ORDERS_URL = '/orders'
export const PRODUCTS_URL = '/products'
export const ROOT_URL = '/'
export const SETTINGS_URL = '/settings'
