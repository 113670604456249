export const getName = state => state?.settings?.name
export const getTradingName = state => state?.settings?.trading_name
export const getAddress = state => state?.settings?.address
export const getAddressOwnerType = state => state?.settings?.address?.owner_type
export const getAddressOwnerId = state => state?.settings?.address?.owner_id
export const getAddressCountryCode = state => state?.settings?.address?.country_code
export const getAddressCountry = state => state?.settings?.address?.country
export const getAddressLine1 = state => state?.settings?.address?.line1
export const getAddressLine2 = state => state?.settings?.address?.line2
export const getTownCity = state => state?.settings?.address?.city
export const getAddressState = state => state?.settings?.address?.state
export const getAddressPostalCode = state => state?.settings?.address?.postal_code
export const getAddressPhoneNumber = state => state?.settings?.address?.phone_number
export const getAddressDefault = state => state?.settings?.address?.default
export const getAddressLatitude = state => state?.settings?.address?.latitude
export const getAddressLongitude = state => state?.settings?.address?.longitude
export const getAddressType = state => state?.settings?.address?.address_type
export const getAddressActive = state => state?.settings?.address?.active
export const getFullAddress = state => state?.settings?.address_string
export const getDealershipUrl = state => state?.settings?.homepage_url
export const getSupportEmail = state => state?.settings?.email
export const getTermsOfSale = state => state?.settings?.terms_of_sale
export const getFacebookUrl = state => state?.settings?.facebook_url
export const getTwitterUrl = state => state?.settings?.twitter_url
export const getYoutubeUrl = state => state?.settings?.youtube_url
export const getInstagramUrl = state => state?.settings?.instagram_url
export const getRegistrationNumber = state => state?.settings?.registration_number
export const getPhoneNumber = state => state?.settings?.phone_number
// TODO: remove merchant key when testing complete
export const getMerchantKey = state => state?.settings?.merchant_key
