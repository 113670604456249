import {createSlice} from '@reduxjs/toolkit'

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {},
  reducers: {
    setCurrentUser: (state, action) => action.payload,
  },
})

export const currentUserReducer = currentUserSlice.reducer
export const {setCurrentUser} = currentUserSlice.actions