import {apiClient} from '../client'
import {clearAuth, getToken} from '../../utilities/auth-helpers/authHelpers'

export const fetchCurrentUser = async () => {
  if (getToken()) {
    const {admin} = await apiClient.get('admins/me').catch(Promise.reject)
    return admin
  } else {
    clearAuth()
    return Promise.reject()
  }
}