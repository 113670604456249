import React from 'react'
import {string} from 'prop-types'
import {FormCheck} from 'react-bootstrap'
import styled from 'styled-components'
import {DARK} from '../../constants/colours'

const propTypes = {label: string}
const defaultProps = {label: ''}

const Check = styled(FormCheck)`
  label { cursor: pointer }
  .custom-control-label::before {
    border: 1px solid ${DARK};
    border-radius: 0;
  }

  .custom-control-input:checked~.custom-control-label::before {
    border-color: ${DARK};
    background-color: ${DARK};
  }
`

const CustomCheckbox = ({label, ...rest}) => {
  return (
    <Check custom>
      <Check.Input {...rest} />
      <Check.Label>
        <div className='font-15 ml-2'>{label}</div>
      </Check.Label>
    </Check>
  )
}

CustomCheckbox.propTypes = propTypes
CustomCheckbox.defaultProps = defaultProps

export default CustomCheckbox
