import {BENTLEY_CSV, BENTLEY_IMAGES, BENTLEY_VIDEOS} from '../constants/awsFolders'
import Console from '../utilities/ConsoleUtil'
import AWS from 'aws-sdk'
import {toast} from 'react-hot-toast'
import {localise} from './LocalizationServices'
import {
  REACT_APP_AWS_ACCESS_KEY_ID,
  REACT_APP_AWS_S3_BUCKET,
  REACT_APP_AWS_S3_REGION,
  REACT_APP_AWS_SECRET_ACCESS_KEY,
} from '../constants/env'


const s3 = new AWS.S3({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: REACT_APP_AWS_S3_REGION,
})

const AWS_CONFIG_EXPIRES = 360000

const folders = [BENTLEY_CSV, BENTLEY_IMAGES, BENTLEY_VIDEOS]

const validFolder = folder => folders.includes(folder)

const awsS3UploaderService = (file, folder) => {
  if (!validFolder(folder)) {
    Console.dev(localise('aws.invalidFolder'), 'error')
    return
  }

  const fileName = `${folder}/${file.name}`

  try {
    const params = {
      Key: fileName,
      Bucket: REACT_APP_AWS_S3_BUCKET,
      Body: file,
      ContentType: file.type,
    }

    return new Promise((resolve, reject) => {
      const handleErr = err => {
        toast.error(err.message || localise('toast.awsS3StorageError'))
        reject(err)
      }

      s3.putObject(params, err => {
        if (err) {
          handleErr(err)
        } else {
          s3.getSignedUrl('getObject', {
            Key: fileName,
            Bucket: REACT_APP_AWS_S3_BUCKET,
            Expires: AWS_CONFIG_EXPIRES,
          }, (err, url) => {
            if (err) {
              handleErr(err)
            } else {
              resolve(url)
            }
          })
        }
      })
    })
  } catch (err) {
    Console.dev(err, 'error')
    toast.error(localise('toast.awsS3StorageError'))
  }
}

export default awsS3UploaderService