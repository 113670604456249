import {createSlice} from '@reduxjs/toolkit'

const customerSlice = createSlice({
  name: 'customer',
  initialState: {},
  reducers: {
    setCustomer: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
  },
})

export const customerReducer = customerSlice.reducer
export const {setCustomer} = customerSlice.actions
