import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import MainLayout from './layouts/MainLayout'
import {DASHBOARD_URL} from '../constants/urls'
import useAuth from '../hooks/useAuth'

const RouteWithLayout = ({component: Component, layout: Layout = MainLayout, requiresAuth = true, ...rest}) => {
  const {toSignOutUrl} = useAuth()

  const authenticated = localStorage.getItem('bearerToken')
  const layout = props => <Layout {...props}><Component {...props} /></Layout>
  const auth = props => authenticated ? layout(props) : toSignOutUrl()
  const unauth = props => !authenticated ? layout(props) : <Redirect to={DASHBOARD_URL} />

  return (
    <Route
      {...rest}
      render={props => requiresAuth ? auth(props) : unauth(props)}
    />
  )
}

export default RouteWithLayout