import React, {useEffect, useMemo, useState} from 'react'
import Card from '../../components/cards/Card'
import NUMBER_ICON from '../../assets/svgs/number-icon.svg'
import StatCard from '../../components/cards/StatCard'
import {Col, Row} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import ColumnChart from '../../components/charts/ColumnChart'
import {fetchOrderNumbers, fetchOrderValues} from '../../api/dashboard/dashboard'
import TabMenu from '../../components/navs/TabMenu'
import {fetchSettings} from '../../api/settings/settings'
import {useSelector} from 'react-redux'
import {getCurrentUserCurrencySymbol} from '../../store/selectors/currentUserSelectors'

const PERIOD = {
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
}

const Dashboard = () => {
  const [name, setName] = useState('')
  const [numberData, setNumberData] = useState({})
  const [numberPeriod, setNumberPeriod] = useState(PERIOD.WEEK)
  const [valueData, setValueData] = useState({})
  const [valuePeriod, setValuePeriod] = useState(PERIOD.WEEK)

  const currencySymbol = useSelector(getCurrentUserCurrencySymbol)

  useMemo(() => {
    fetchOrderNumbers(numberPeriod).then(({chart_data, total}) => setNumberData({total, chart_data}))
  }, [numberPeriod])

  useMemo(() => {
    fetchOrderValues(valuePeriod).then(({chart_data, total}) => setValueData(
      {
        total,
        chart_data: chart_data.map(values => ([
          values[0],
          typeof values[1] === 'string' ? parseFloat(values[1]?.replace(/,/g, '')) : values[1],
        ])),
      }
    ))
  }, [valuePeriod])

  useEffect(() => {
    fetchSettings().then(({name}) => setName(name))
  }, [])

  const chartData = [
    {
      data:  numberData?.chart_data,
      heading: localise('headings.numberOfOrders'),
      tabHandler: setNumberPeriod,
    },
    {
      data: valueData?.chart_data,
      heading: localise('headings.valueOfOrders'),
      tabHandler: setValuePeriod,
    },
  ]

  const statCardData = [
    {
      heading: localise('headings.totalNumberOfOrders'),
      icon: {src: NUMBER_ICON},
      value: numberData.total,
    },
    {
      heading: localise('headings.totalValueOfOrders'),
      icon: {value: currencySymbol},
      value: valueData.total,
    },
  ]

  return (
    <Row className='mb-5'>
      <Col xs={12}>
        <div className='font-20 font-bold mb-3'>{name}</div>
      </Col>
      {
        statCardData?.map(({heading, icon, value}, index) => (
          <Col md={6}>
            <StatCard
              className={`d-flex ${index !== statCardData.length - 1 ? 'mb-4 mb-md-0' : ''}`}
              heading={heading}
              headingClasses='mb-0'
              icon={icon}>
              {value}
            </StatCard>
          </Col>
        ))
      }

      {
        chartData.map(({data, heading, tabHandler}) =>
          <Col xs={12} className='mt-4'>
            <Card className='py-2 px-3'>
              <div className='d-flex my-auto justify-content-between'>
                <div className='font-bold font-17 p-3'>{heading}</div>
                <div>
                  {
                    <TabMenu
                      className='chart-tab font-13'
                      data={[
                        {
                          eventKey: PERIOD.WEEK,
                          title: localise('headings.weeks'),
                        },
                        {
                          eventKey: PERIOD.MONTH,
                          title: localise('headings.months'),
                        },
                        {
                          eventKey: PERIOD.YEAR,
                          title: localise('headings.years'),
                        },
                      ]}
                      defaultActiveKey={PERIOD.WEEK}
                      onSelect={tabHandler}
                    />
                  }
                </div>
              </div>
              <ColumnChart chartProps={{data}} />
            </Card>
          </Col>
        )
      }
    </Row>
  )
}

export default Dashboard
