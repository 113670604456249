import React from 'react'
import {bool, node, string} from 'prop-types'
import TextTruncate from 'react-text-truncate'

const propTypes = {
  className: string,
  children: node.isRequired,
  childrenClasses: string,
  heading: string,
  headingClasses: string,
  truncateChildren: bool,
}

const defaultProps = {
  className: '',
  children: '',
  childrenClasses: '',
  heading: '',
  headingClasses: '',
  truncateChildren: null,
}

const Paragraph = ({className, children, childrenClasses, heading, headingClasses, truncateChildren}) => {
  return (
    <div className={`paragraph-container ${className}`}>
      {
        heading &&
        <div className={`font-13 font-bold text-capitalize ${headingClasses}`}>
          {heading}
        </div>
      }
      <div className={`font-15 ${childrenClasses}`}>
        {
          truncateChildren && typeof children === 'string' && children.length > 250
            ? (
              <TextTruncate
                line={1}
                element='span'
                text={children}
              />
            )
            : children
        }
      </div>
    </div>
  )
}

Paragraph.propTypes = propTypes
Paragraph.defaultProps = defaultProps

export default Paragraph
