import {localise} from '../services/LocalizationServices'

const useOrders = () => {
  const statuses = [
    {
      label: localise('form.label.new'),
      param: 'pending',
    },
    {
      label: localise('form.label.contactedCustomer'),
      param: 'contacted_customer',
    },
    {
      label: localise('form.label.fittingBooked'),
      param: 'fitting_booked',
    },
    {
      label: localise('form.label.complete'),
      param: 'completed',
    },
    {
      label: localise('form.label.cancelled'),
      param: 'rejected',
    },
    {
      label: localise('form.label.refunded'),
      param: 'returned',
    },
  ]
  return {statuses}
}

export default useOrders
