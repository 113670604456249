import React from 'react'
import {node, string} from 'prop-types'

const propTypes = {
  className: string,
  children: node,
}

const defaultProps = {
  className: '',
  children: null,
}

const Card = ({className, children}) => {
  return (
    <div className={`card-container rounded-lg box-shadow bg-white ${className}`}>
      {children}
    </div>
  )
}

Card.propTypes = propTypes
Card.defaultProps = defaultProps

export default Card
