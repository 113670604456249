import React, {useState} from 'react'
import {SETTINGS_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'
import {useHistory} from 'react-router-dom'
import Prompt from '../../components/modals/Prompt'
import {updateSettings} from '../../api/settings/settings'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {toast} from 'react-hot-toast'
import SettingsForm from './SettingsForm'

const SettingsEdit = () => {
  const {push} = useHistory()

  const [dirty, setDirty] = useState(false)

  const toSettings = () => push(SETTINGS_URL)

  const update = values => {
    toggleLoading()
    updateSettings({
      name: values?.name,
      trading_name: values?.trading_name,
      terms_of_sale: values?.terms_of_sale,
      email: values?.email,
      homepage_url: values?.homepage_url,
      address: values?.address,
      facebook_url: values?.facebook,
      twitter_url: values?.twitter,
      instagram_url: values?.instagram,
      youtube_url: values?.youtube,
      registration_number: values?.registrationNumber,
      phone_number: values?.phoneNumber,
      // TODO: remove merchant key when testing complete
      merchant_key: values?.merchant_key,
    })
      .then(() => {
        setDirty(false)
        toast.success(localise('toast.settingsUpdateSuccess'))
        toSettings()
      })
      .finally(toggleLoading)
  }

  return (
    <>
      <Prompt when={dirty} />
      <SettingsForm
        breadcrumbs={[
          {
            active: false,
            href: SETTINGS_URL,
            text: localise('nav.settings'),
          },
          {
            active: true,
            text: localise('nav.edit'),
          },
        ]}
        onSubmit={update}
        onCancel={toSettings}
        isDirty={setDirty}
      />
    </>
  )
}

export default SettingsEdit
