import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {bool, string} from 'prop-types'
import Modal from './Modal'
import {localise} from '../../services/LocalizationServices'
import {Button} from 'react-bootstrap'

const propTypes = {
  message: string,
  when: bool,
}

const defaultProps = {
  message: '',
  when: false,
}

const Prompt = props => {
  const {
    message,
    when,
  } = props

  const history = useHistory()

  const [showPrompt, setShowPrompt] = useState(false)
  const [newPath, setNewPath] = useState('')

  const blockTransition = useCallback(() => history.block(prompt => {
    setNewPath(prompt.pathname)
    setShowPrompt(true)
    return 'true'
  }), [history])

  useEffect(() => {
    if (when) {
      blockTransition()
    } else {
      history.block(() => {})
    }

    return () => {
      history.block(() => {})
    }
  }, [blockTransition, history, when])

  const handleOK = useCallback(() => {
    history.block(() => {})
    history.push(newPath)
  }, [newPath, history])

  const handleCancel = useCallback(() => {
    history.block(() => {})
    history.push(history.location.pathname)
    setShowPrompt(false)
    blockTransition()
  }, [blockTransition, history])

  return showPrompt ? (
    <Modal
      show={showPrompt}
      headerText={localise('modalText.unsavedChanges')}
      bodyClasses='p-3'
      bodyText={message || localise('form.validation.unsavedChangesConfirm')}
      toggle={handleCancel}
      footerElement={<>
        <Button variant='outline' className='font-13 font-bold py-1 mr-2 rounded' onClick={handleCancel}>
          {localise('buttonText.cancel')}
        </Button>
        <Button variant='secondary' className='font-13 font-bold py-1 rounded' onClick={handleOK}>
          {localise('buttonText.confirm')}
        </Button>
      </>}>
    </Modal>
  ) : null
}

Prompt.propTypes = propTypes
Prompt.defaultProps = defaultProps

export default Prompt