import runtimeEnv from '@mars/heroku-js-runtime-env'

const {
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_USE_REFRESH_TOKEN,
  REACT_APP_AWS_S3_REGION,
  REACT_APP_AWS_ACCESS_KEY_ID,
  REACT_APP_AWS_SECRET_ACCESS_KEY,
  REACT_APP_AWS_S3_BUCKET,
  REACT_APP_ENVIRONMENT,
} = runtimeEnv()

export {
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_USE_REFRESH_TOKEN,
  REACT_APP_AWS_S3_REGION,
  REACT_APP_AWS_ACCESS_KEY_ID,
  REACT_APP_AWS_SECRET_ACCESS_KEY,
  REACT_APP_AWS_S3_BUCKET,
  REACT_APP_ENVIRONMENT,
}