import React from 'react'
import {node, object, string} from 'prop-types'
import Card from './Card'
import {Image} from 'react-bootstrap'

const propTypes = {
  className: string,
  children: node,
  heading: string,
  headingClasses: string,
  icon: object,
}

const defaultProps = {
  className: '',
  children: '',
  heading: 'heading',
  headingClasses: 'mb-2',
  icon: null,
}

const StatCard = ({className, children, heading, headingClasses, icon}) => {
  const renderSection = (section, index) => {
    const count = React.Children.count(children)
    return (
      <div className={count > 1 && count - 1 !== index ? 'border-bottom mb-1' : ''}>
        <div className='pl-3'>
          {section}
        </div>
      </div>
    )
  }

  const renderIcon = () => {
    const {className, src, value} = icon
    return src
      ? <Image src={src} className={`ml-2 my-auto ${className}`} {...icon} />
      : <div className='font-30 font-bold font-primary-green ml-2 px-2'>{value}</div>
  }

  return (
    <Card className={`stat-card-container px-0 py-2 ${className}`}>
      { icon && renderIcon() }
      <div>
        <div className={`ml-3 font-15 font-bold ${headingClasses}`}>
          {heading}
        </div>
        <div className='content'>
          { React.Children.map(children, (child, i) => renderSection(child , i)) }
        </div>
      </div>
    </Card>
  )
}

StatCard.propTypes = propTypes
StatCard.defaultProps = defaultProps

export default StatCard
