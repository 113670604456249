import React, {useEffect, useRef, useState} from 'react'
import {Button} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import {exportOrders, fetchOrders} from '../../api/order/order'
import {LIMIT} from '../../constants/api'
import {calculateOffset} from '../../components/pagination/Pagination'
import {useHistory} from 'react-router-dom'
import {ORDERS_URL} from '../../constants/urls'
import OrdersTable from '../../components/tables/OrdersTable'
import ListView from '../common/ListView'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import useOrders from '../../hooks/useOrders'

const Orders = () => {
  const {statuses: orderStatuses} = useOrders()
  const {push} = useHistory()

  const downloadLink = useRef(null)

  const [orders, setOrders] = useState([])
  const [total, setTotal] = useState()
  const [offset, setOffset] = useState(0)
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(true)
  const [statuses, setStatuses] = useState({})
  const [fileUrl, setFileUrl] = useState(null)

  useEffect(() => {
    setLoading(true)
    fetchOrders({offset, query, statuses})
      .then(({orders, total}) => {
        setTotal(total)
        setOrders(orders.map(order =>
          ({
            ...order,
            orderCode: order?.order_code,
            orderValue: order?.order_value?.display,
            date: order?.created_at,
            display_status: orderStatuses?.filter(({param}) => order?.status === param)[0]?.label,
          })
        ))
      }).finally(() => setLoading(false))
    //eslint-disable-next-line
  }, [offset, query, statuses])

  const exportCSV = () => {
    toggleLoading()
    exportOrders({offset, query, statuses})
      .then(({data, headers}) => {
        const file = new File([data], 'orders.csv', {type: headers['content-type']})
        const url = URL.createObjectURL(file)
        setFileUrl(url)
        downloadLink?.current?.click()
      })
      .finally(toggleLoading)
  }

  const toOrder = id => push(`${ORDERS_URL}/${id}`)

  return (
    <ListView
      buttons={(
        <Button variant='secondary' className='font-13' onClick={exportCSV}>
          {localise('buttonText.exportCSV')}
        </Button>
      )}
      heading={localise('headings.orders')}>
      <OrdersTable
        count={total}
        data={orders}
        handleSearch={setQuery}
        loading={loading}
        onRowClick={toOrder}
        onStatusChange={setStatuses}
        pagination={{
          onPageChange: page => setOffset(calculateOffset(page, LIMIT)),
          offset,
          total,
        }}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a href={fileUrl} ref={downloadLink} download hidden />
    </ListView>
  )
}

export default Orders
