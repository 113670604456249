import {localise} from '../services/LocalizationServices'
import {toast} from 'react-hot-toast'

const useFileUploader = ({files, maxFiles, maxImgByteSize, maxVideoByteSize}) => {
  const MILLION = 1000000
  const BILLION = 1000000000
  const maxFileCount = maxFiles?.length

  const FILE_ERROR_TYPES = {
    ERROR_TOO_MANY_FILES: 'too-many-files',
    ERROR_TOO_MANY_VIDEO_FILES: 'too-many-video-files',
    ERROR_TOO_MANY_IMAGE_FILES: 'too-many-image-files',
    ERROR_FILE_TOO_LARGE: 'file-too-large',
    ERROR_FILE_TOO_SMALL: 'file-too-small',
    ERROR_FILE_INVALID: 'file-invalid-type',
    IMAGE_TOO_LARGE: 'image-too-large',
    VIDEO_TOO_LARGE: 'video-too-large',
  }

  const handleFileRejections = fileRejections => {
    let toastTooManyVideoFilesId,
      toastTooManyImageFilesId,
      toastFileTooSmallId,
      toastTooManyFilesId,
      toastFileInvalidId,
      toastGenericErrorId,
      toastImageTooLargeId,
      toastVideoTooLargeId = null

    const {
      ERROR_TOO_MANY_FILES,
      ERROR_TOO_MANY_IMAGE_FILES,
      ERROR_TOO_MANY_VIDEO_FILES,
      ERROR_FILE_TOO_SMALL,
      ERROR_FILE_INVALID,
      IMAGE_TOO_LARGE,
      VIDEO_TOO_LARGE,
    } = FILE_ERROR_TYPES

    fileRejections?.forEach(({errors}) => {
      errors?.forEach(({code, message}) => {
        if (code === ERROR_TOO_MANY_FILES && !toastTooManyFilesId) {
          toastTooManyFilesId = toast.error(localise('toast.tooManyFilesError', {maxFiles}))
        }

        if (code === ERROR_TOO_MANY_IMAGE_FILES && !toastTooManyImageFilesId) {
          toastTooManyImageFilesId = toast.error(localise('toast.tooManyImageFilesError', {maxFiles}))
        }

        if (code === ERROR_TOO_MANY_VIDEO_FILES && !toastTooManyVideoFilesId) {
          toastTooManyVideoFilesId = toast.error(localise('toast.tooManyVideoFilesError', {maxFiles}))
        }

        if (code === ERROR_FILE_TOO_SMALL && !toastFileTooSmallId) {
          toastFileTooSmallId = toast.error(localise('toast.fileTooSmallError'))
        }

        if (code === ERROR_FILE_INVALID && !toastFileInvalidId) {
          toastFileInvalidId = toast.error(localise('toast.invalidFile'))
        }

        if (code === IMAGE_TOO_LARGE && !toastImageTooLargeId) {
          toastImageTooLargeId = toast.error(message)
        }

        if (code === VIDEO_TOO_LARGE && !toastVideoTooLargeId) {
          toastVideoTooLargeId = toast.error(message)
        }

        if (!Object.values(FILE_ERROR_TYPES).includes(code) && !toastGenericErrorId) {
          toastGenericErrorId = toast.error(message || localise('toast.genericError'))
        }
      })
    })
  }

  const validations = file => {
    if (file?.type?.includes('video')) {
      if (file?.size > maxVideoByteSize) {
        return {
          code: FILE_ERROR_TYPES.VIDEO_TOO_LARGE,
          message: localise('toast.videoTooLargeError', {maxVideoGBSize: maxVideoByteSize / BILLION}),
        }
      }

      if (files?.length >= maxFiles) {
        return {
          code: FILE_ERROR_TYPES.ERROR_TOO_MANY_VIDEO_FILES,
          message: localise('toast.tooManyVideoFilesError', {maxFiles}),
        }
      }
    }

    if (file?.type?.includes('image')) {
      if (file?.size > maxImgByteSize) {
        return {
          code: FILE_ERROR_TYPES.IMAGE_TOO_LARGE,
          message: localise('toast.imgTooLargeError', {maxImgMBSize: maxImgByteSize / MILLION}),
        }
      }

      if (files?.length >= maxFiles) {
        return {
          code: FILE_ERROR_TYPES.ERROR_TOO_MANY_IMAGE_FILES,
          message: localise('toast.tooManyImageFilesError', {maxFiles}),
        }
      }
    }

    return null
  }

  return {
    BILLION,
    handleFileRejections,
    maxFileCount,
    MILLION,
    validations,
  }
}

export default useFileUploader
