import React from 'react'
import TopNav from '../../components/navs/TopNav'
import {Col, Container, Row} from 'react-bootstrap'
import SideNav from '../../components/navs/SideNav'
import styled from 'styled-components'
import useNav from '../../hooks/useNav'

const MainLayoutContainer = styled.div`overflow-x: hidden;`
const TopNavContainer = styled(Col)`max-width: 100vw;`
const ChildrenContainer = styled(Col)`max-width: 100vw;`

const MainLayout = ({children}) => {
  const {collapseBreakpoint} = useNav()

  return (
    <MainLayoutContainer className='d-flex'>
      <div className={`d-${collapseBreakpoint}-block d-none p-0 box-shadow-right`}>
        <SideNav />
      </div>
      <div className='flex-grow-1'>
        <Container fluid className={`ml-${collapseBreakpoint}-1 ml-0`}>
          <Row className={`py-${collapseBreakpoint}-2 py-0`}>
            <TopNavContainer>
              <TopNav />
            </TopNavContainer>
          </Row>
          <Row>
            <ChildrenContainer className={`mx-${collapseBreakpoint}-2 my-4`}>
              {children}
            </ChildrenContainer>
          </Row>
        </Container>
      </div>
    </MainLayoutContainer>
  )
}

export default MainLayout
