import {useEffect, useState} from 'react'
import {authenticate} from '../../api/authentication/auth'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router'
import handleError from '../../utilities/api-helpers/handleError'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {fetchCurrentUser} from '../../api/admin/admin'
import {setCurrentUser} from '../../store/slices/currentUserSlice'
import {DASHBOARD_URL} from '../../constants/urls'
import useAuth from '../../hooks/useAuth'
import {toast} from 'react-hot-toast'

const AuthCode = () => {
  const dispatch = useDispatch()
  const {push} = useHistory()
  const {signOutUrl, toSignOutUrl} = useAuth()
  const [once, setOnce] = useState(false)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const code = query.get('code')
    const error = query.get('error')

    if (code && signOutUrl && !once) {
      setOnce(true)
      toggleLoading()
      authenticate(code)
        .then(async () => {
          const user = await fetchCurrentUser()

          if (user) {
            dispatch(setCurrentUser(user))
            push(DASHBOARD_URL)
          }
        })
        .catch(err => handleError(err).then(toSignOutUrl))
        .finally(toggleLoading)
    } else if (error && signOutUrl && !once) {
      setOnce(true)
      toast.error(error)
      setTimeout(() => toSignOutUrl(), 3000)
    }
  }, [once, dispatch, push, toSignOutUrl, signOutUrl])

  return null
}

export default AuthCode