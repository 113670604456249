import React from 'react'
import {node, string} from 'prop-types'
import {Col, Row} from 'react-bootstrap'

const propTypes = {
  buttons: node,
  children: node,
  className: string,
  heading: string,
}

const defaultProps = {
  buttons: null,
  children: null,
  className: '',
  heading: '',
}

const ListView = ({buttons, children, className, heading}) => {
  return (
    <Row className={className}>
      <Col xs='12'>
        <Row className='mb-3'>
          <Col sm='6' className='font-20 font-bold'>{heading}</Col>
          <Col sm='6' className='text-sm-right mt-2 mt-sm-0'>{buttons}</Col>
        </Row>
        <Row>
          <Col>
            {children}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

ListView.propTypes = propTypes
ListView.defaultProps = defaultProps

export default ListView
