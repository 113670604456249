import React from 'react'
import {bool, string} from 'prop-types'
import styled from 'styled-components'
import {DARK, PRIMARY_RED} from '../../constants/colours'
import {localise} from '../../services/LocalizationServices'

const propTypes = {
  active: bool,
  className: string,
  text: string,
}

const defaultProps = {
  active: false,
  className: '',
  text: null,
}

const StatusBadgeContainer = styled.div`
  background-color: ${PRIMARY_RED};
  &.active {
    background-color: ${DARK};
  }
`

const StatusBadge = ({active, className, text}) => {
  return (
    <StatusBadgeContainer
      className={`status-badge-container d-inline-block px-2 font-white ${active ? 'active' : ''} ${className}`}>
      {text || localise(`status.${active ? 'active' : 'inactive'}`)}
    </StatusBadgeContainer>
  )
}

StatusBadge.propTypes = propTypes
StatusBadge.defaultProps = defaultProps

export default StatusBadge