/* eslint-disable max-len */
import {DateTime} from 'luxon'
import {EUROPEAN_DATE_FORMAT} from '../../constants/dates'

export const getOrderId = state => state?.order?.id
export const getOrderCode = state => state?.order?.order_code
export const getOrderCreatedAt = state => DateTime.fromSeconds(state?.order?.created_at || 0).toFormat(EUROPEAN_DATE_FORMAT)
export const getOrderDealership = state => state?.order?.dealership
export const getOrderStatus = state => state?.order?.status
export const getOrderPriceItems = state => state?.order?.price_items
export const getOrderItems = state => state?.order?.ordered_items

export const getOrderAddressActive = state => state?.order?.address?.active
export const getOrderAddressCity = state => state?.order?.address?.city
export const getOrderAddressCountryCode = state => state?.order?.address?.country_code
export const getOrderAddressCreatedAt = state => state?.order?.address?.created_at
export const getOrderAddressDefault = state => state?.order?.address?.default
export const getOrderAddressId = state => state?.order?.address?.id
export const getOrderAddressLine1 = state => state?.order?.address?.line1
export const getOrderAddressLine2 = state => state?.order?.address?.line2
export const getOrderAddressPhoneNumber = state => state?.order?.address?.phone_number
export const getOrderAddressPostalCode = state => state?.order?.address?.postal_code
export const getOrderAddressState = state => state?.order?.address?.state

export const getOrderPaymentActive = state => state?.order?.payment?.active
export const getOrderPaymentId = state => state?.order?.payment?.id
export const getOrderPaymentStatus = state => state?.order?.payment?.status
export const getOrderPaymentReference = state => state?.order?.payment?.reconciliation_id
export const getOrderPaymentTransactionId = state => state?.order?.payment?.transaction_id

export const getOrderUserDealership = state => state?.order?.user?.dealership
export const getOrderUserEmail = state => state?.order?.user?.email
export const getOrderUserFirstName = state => state?.order?.user?.first_name
export const getOrderUserLastName = state => state?.order?.user?.last_name
export const getOrderUserFullName = state => `${state?.order?.user?.first_name} ${state?.order?.user?.last_name}`
export const getOrderUserId = state => state?.order?.user?.id
export const getOrderUserPhoneNumber = state => state?.order?.phone_number

export const getOrderVehicleId = state => state?.order?.vehicle?.id
export const getOrderVehicleName = state => state?.order?.vehicle?.name
export const getOrderVehicleYearId = state => state?.order?.year?.id
export const getOrderVehicleYearName = state => state?.order?.year?.name
