import React from 'react'
import {Tabs, Tab} from 'react-bootstrap'
import {array, func, string} from 'prop-types'
import {v4} from 'uuid'
import Console from '../../utilities/ConsoleUtil'
import styled from 'styled-components'
import {PRIMARY_GREEN} from '../../constants/colours'

const propTypes = {
  className: string,
  data: array.isRequired,
  defaultActiveKey: string,
  onSelect: func,
}

const defaultProps = {
  className: 'font-14',
  data: [
    {
      tabClassName: '',
      eventKey: 'A',
      title: 'A',
      content: 'A',
    },
    {
      tabClassName: '',
      eventKey: 'B',
      title: 'B',
      content: 'B',
    },
  ],
  defaultActiveKey: 'A',
  onSelect: label => Console.dev(`onSelect label => ${label}`),
}

const TabNavContainer = styled(Tabs)`
  border: 0;

  a.nav-item.nav-link {
    border: 0;
    font-family: Bentley, sans-serif;
    padding: 0;
    &.active {
      border-bottom: 3px solid ${PRIMARY_GREEN} !important;
      font-family: BentleyBold, sans-serif;
    }
  }
`

const TabMenu = ({className, defaultActiveKey, data, onSelect}) =>
  <TabNavContainer
    className={`tab-nav-container font-bold ${className}`}
    defaultActiveKey={data[0].eventKey || defaultActiveKey}
    onSelect={onSelect}>
    {
      data.map(({eventKey, content, tabClassName, title}) =>
        <Tab tabClassName={`pl-0 mr-3 ${tabClassName}`} eventKey={eventKey} title={title} key={v4()}>
          {content}
        </Tab>
      )
    }
  </TabNavContainer>

TabMenu.propTypes = propTypes
TabMenu.defaultProps = defaultProps

export default TabMenu
