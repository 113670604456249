import {toast} from 'react-hot-toast'
import {localise} from '../../services/LocalizationServices'

export default text => {
  navigator.permissions.query({name: 'clipboard-write'})
    .then(({state}) => {
      if (state === 'granted' || state === 'prompt') {
        navigator.clipboard.writeText(text)
          .then(
            () => toast.success(localise('toast.copiedToClipboard')),
            () => toast.error(localise('toast.genericError'))
          )
      }
    })
}
