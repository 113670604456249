import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchOrderValues = async period => {
  const {
    total,
    chart_data,
  } = await apiClient.get('statistics/orders/values', {params: {period}}).catch(handleError)

  return {total, chart_data}
}

export const fetchOrderNumbers = async period => {
  const {
    total,
    chart_data,
  } = await apiClient.get('statistics/orders/numbers', {params: {period}}).catch(handleError)

  return {total, chart_data}
}
