import React, {useEffect} from 'react'
import {localise} from '../../services/LocalizationServices'
import {Button, Row} from 'react-bootstrap'
import Paragraph from '../../components/paragraph/Paragraph'
import {EDIT_URL, SETTINGS_URL} from '../../constants/urls'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {
  getDealershipUrl,
  getFullAddress,
  getName,
  getSupportEmail,
  getTermsOfSale,
} from '../../store/selectors/settingsSelector'
import {v4} from 'uuid'
import StatCard from '../../components/cards/StatCard'
import {fetchSettings} from '../../api/settings/settings'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {setSettings} from '../../store/slices/settingsSlice'

const Settings = () => {
  const {push} = useHistory()
  const dispatch = useDispatch()

  const fullAddress = useSelector(getFullAddress)
  const dealerHomepageUrl = useSelector(getDealershipUrl)
  const name = useSelector(getName)
  const supportEmail = useSelector(getSupportEmail)
  const termsOfSale = useSelector(getTermsOfSale)

  useEffect(() => {
    toggleLoading()
    fetchSettings()
      .then(settings => dispatch(setSettings(settings)))
      .finally(toggleLoading)
  }, [dispatch])

  const DATA = [
    {
      heading: localise('headings.name'),
      body: name,
    },
    {
      heading: localise('headings.address'),
      body: fullAddress,
    },
    {
      heading: localise('headings.retailerHomepageUrl'),
      body: dealerHomepageUrl,
    },
    {
      heading: localise('headings.supportEmail'),
      body: supportEmail,
    },
    {
      heading: localise('headings.termsOfSale'),
      body: termsOfSale,
    },
  ]

  const toEdit = () => push(`${SETTINGS_URL}${EDIT_URL}`)

  return (
    <>
      <div className='d-flex justify-content-between mb-3'>
        <div className='font-20 font-bold'>{localise('nav.settings')}</div>
        <Button variant='secondary' className='font-13' onClick={toEdit}>
          {localise('buttonText.edit')}
        </Button>
      </div>
      <StatCard
        className='font-15 mb-2'
        heading={localise('headings.retailerDetails')}>
        <Row className='mr-0'>
          {
            DATA?.map(({heading, body}, index) => {
              if (body) {
                return (
                  <Paragraph
                    key={v4()}
                    className={`col-sm-6 pr-0 pl-2 mt-2 ${index !== DATA?.length - 1 ? 'border-bottom' : 'col-sm-12'}`}
                    childrenClasses='mb-3 px-2'
                    heading={heading}
                    headingClasses='px-2'
                    truncateChildren={heading === localise('headings.termsOfSale')}>
                    {body}
                  </Paragraph>
                )
              }
              return null
            })
          }
        </Row>
      </StatCard>
    </>
  )
}

export default Settings
