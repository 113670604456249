import React from 'react'
import StatCard from '../../../components/cards/StatCard'
import {localise} from '../../../services/LocalizationServices'
import {Col, Image, Row} from 'react-bootstrap'
import Paragraph from '../../../components/paragraph/Paragraph'
import {useSelector} from 'react-redux'
import {
  getOrderAddressCity,
  getOrderAddressCountryCode,
  getOrderAddressLine1,
  getOrderAddressLine2,
  getOrderAddressPhoneNumber,
  getOrderAddressPostalCode,
  getOrderAddressState,
  getOrderPaymentReference,
  getOrderUserEmail,
  getOrderUserFullName,
  getOrderUserPhoneNumber,
  getOrderVehicleName,
  getOrderVehicleYearName,
} from '../../../store/selectors/orderSelectors'
import {v4} from 'uuid'
import VWPayIcon from '../../../assets/svgs/vw-pay.svg'

const CustomerDetails = () => {
  const fullName = useSelector(getOrderUserFullName)
  const email = useSelector(getOrderUserEmail)
  const number = useSelector(getOrderUserPhoneNumber)
  const secondaryNumber = useSelector(getOrderAddressPhoneNumber)
  const line1 = useSelector(getOrderAddressLine1)
  const line2 = useSelector(getOrderAddressLine2)
  const city = useSelector(getOrderAddressCity)
  const state = useSelector(getOrderAddressState)
  const postcode = useSelector(getOrderAddressPostalCode)
  const countryCode = useSelector(getOrderAddressCountryCode)
  const paymentReference = useSelector(getOrderPaymentReference)
  const vehicleModel = useSelector(getOrderVehicleName)
  const vehicleYear = useSelector(getOrderVehicleYearName)

  const DATA = [
    {
      heading: localise('headings.name'),
      children: fullName,
    },
    {
      heading: localise('headings.email'),
      children: email,
    },
    {
      heading: localise('headings.phoneNumber'),
      children: number || secondaryNumber || 'N/A',
    },
    {
      heading: localise('headings.address'),
      children: <>
        <div>{line1}</div>
        <div>{line2}</div>
        <div>{city}</div>
        <div>{state}</div>
        <div>{postcode}</div>
        <div>{countryCode}</div>
      </>,
    },
    {
      heading: localise('headings.vehicleModel'),
      children: vehicleModel,
    },
    {
      heading: localise('headings.vehicleYear'),
      children: vehicleYear,
    },
    {
      heading: localise('headings.paymentDetails'),
      children: (
        <div className='d-flex'>
          <Image src={VWPayIcon} className='mr-2' height={24} width={24} />
          <div>
            <div className='font-15'>
              {localise('headings.paymentReference')}
            </div>
            <div className='font-13'>
              {paymentReference}
            </div>
          </div>
        </div>
      ),
    },
  ]

  const parsedData = () => {
    return !paymentReference ? DATA.filter(({heading}) => heading !== localise('headings.paymentDetails')) : DATA
  }

  return (
    <StatCard heading={localise('headings.customerDetails')}>
      {
        parsedData()?.map(item =>
          <Row key={v4()}>
            <Col>
              <Paragraph {...item} />
            </Col>
          </Row>
        )
      }
    </StatCard>
  )
}

export default CustomerDetails
