import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'
import getIndexParams from '../../utilities/api-helpers/getIndexParams'

export const fetchProducts = async opts => {
  const params = getIndexParams(opts)

  const {products, meta: {total}} = await apiClient.get('/products', {params}).catch(handleError)
  return {products, total}
}

const productAction = async (action, url, data) => {
  const {product} = await apiClient[action](url, data).catch(handleError)
  return product
}

export const deleteProduct = async id => {
  const {deleted} = await apiClient.delete(`products/${id}`).catch(handleError)
  return deleted
}

export const createProduct = product => productAction('post', 'products', {product})
export const updateProduct = (id, product) => productAction('put', `products/${id}`, {product})
export const fetchProduct = id => productAction('get', `products/${id}`)
export const setMasterProduct = id => productAction('put', `products/${id}/default_to_master`)
