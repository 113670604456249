import React, {useState} from 'react'
import {array, bool, func, number, string} from 'prop-types'
import ReactDropzone from 'react-dropzone'
import Skeleton from 'react-loading-skeleton'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import Thumbnail from './Thumbnail'
import Console from '../../utilities/ConsoleUtil'
import useFileUploader from '../../hooks/useFileUploader'
import styled from 'styled-components'
import {LIGHT_GREY} from '../../constants/colours'

const propTypes = {
  accept: string,
  className: string,
  createPreview: bool,
  data: array,
  disabled: bool,
  loading: bool,
  maxFiles: number,
  maxImgByteSize: number,
  maxVideoByteSize: number,
  onDragEnd: func,
  onDragStart: func,
  onDrop: func,
  onRemoveClick: func,
  placeholderText: string,
  thumbnailsLoading: bool,
}

const defaultProps = {
  accept: '',
  className: '',
  createPreview: false,
  data: [],
  disabled: false,
  loading: false,
  maxFiles: 1,
  maxImgByteSize: 20000000,
  maxVideoByteSize: 2000000000,
  onDragEnd: () => Console.dev('on drag end'),
  onDragStart: () => Console.dev('on drag start'),
  onDrop: () => Console.dev('on drop'),
  onRemoveClick: () => Console.dev('on remove'),
  placeholderText: '',
  thumbnailsLoading: false,
}

const DROPPABLE_ID = 'droppable'

const DropzoneContainer = styled.div`
  border: 2px dashed ${LIGHT_GREY};
  min-height: 120px;
`

const Dropzone = props => {
  const {
    accept,
    className,
    createPreview,
    data,
    disabled,
    loading,
    maxFiles,
    maxImgByteSize,
    maxVideoByteSize,
    onDragEnd,
    onDragStart,
    onDrop,
    onRemoveClick,
    placeholderText,
    thumbnailsLoading,
  } = props

  const [dropzoneDisabled, setDropzoneDisabled] = useState(disabled)

  const {handleFileRejections, validations} = useFileUploader({files: data, maxFiles, maxImgByteSize, maxVideoByteSize})

  const renderThumbnails = () => data?.length > 0
    ? (
      data
        .map(({url}, index) => (
          <Thumbnail
            key={index}
            index={index}
            id={index}
            src={url}
            loading={thumbnailsLoading}
            onRemoveClick={onRemoveClick}
            createPreview={createPreview}
          />
        )
        )
    )
    : <p className='font-13 font-grey'>{placeholderText}</p>

  return loading
    ? <Skeleton height={120} />
    : (
      <ReactDropzone
        accept={accept}
        disabled={dropzoneDisabled}
        maxFiles={maxFiles}
        onDrop={onDrop}
        onDropRejected={handleFileRejections}
        validator={validations}>
        {
          ({getRootProps, getInputProps}) => (
            <DropzoneContainer className={className}>
              <div {...getRootProps({className: 'p-2 cursor-pointer h-100'})}>
                <input {...getInputProps()} />
                <DragDropContext
                  onDragEnd={dragged => {
                    onDragEnd(dragged)
                    setDropzoneDisabled(disabled !== null ? disabled : false)
                  }}
                  onDragStart={dragged => {
                    setDropzoneDisabled(true)
                    onDragStart(dragged)
                  }}>
                  <Droppable droppableId={DROPPABLE_ID} direction='horizontal'>
                    {
                      provided => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className='d-flex flex-wrap'>
                          {renderThumbnails()}
                          {provided.placeholder}
                        </div>
                      )
                    }
                  </Droppable>
                </DragDropContext>
              </div>
            </DropzoneContainer>
          )
        }
      </ReactDropzone>
    )
}

Dropzone.propTypes = propTypes
Dropzone.defaultProps = defaultProps

export default Dropzone
