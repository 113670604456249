import {
  oauthClient,
  GRANT_TYPE_CLIENT_CREDENTIALS,
  GRANT_TYPE_AUTHORIZATION_CODE,
} from '../client'
import {clearAuth, getRefreshToken, getToken, setAuth} from '../../utilities/auth-helpers/authHelpers'
import {ROOT_URL} from '../../constants/urls'
import handleError from '../../utilities/api-helpers/handleError'

export const logout = async () => {
  await oauthClient.post('revoke', {token: getToken()}).catch(handleError)
  clearAuth()
}

export const refreshFailed = err => {
  clearAuth()
  window.location.href = ROOT_URL
  Promise.reject(err)
}

export const refreshAuthToken = () => new Promise(resolve => {
  oauthClient.post('token', {refresh_token: getRefreshToken()})
    .then(({token}) => {
      if (token) {
        setAuth(token)
      } else {
        clearAuth()
      }
      resolve(token)
    })
    .catch(refreshFailed)
})

export const authenticate = async code => {
  const payload = code
    ? {
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code,
      redirect_uri: 'urn:ietf:wg:oauth:2.0:oob',
    }
    : {
      grant_type: GRANT_TYPE_CLIENT_CREDENTIALS,
    }

  const {token} = await oauthClient.post('token', payload).catch(handleError)
  setAuth(token)
  return token
}
