import React, {useEffect, useState} from 'react'
import {localise} from '../../services/LocalizationServices'
import CustomersTable from '../../components/tables/CustomersTable'
import {fetchCustomers} from '../../api/customer/customers'
import {calculateOffset} from '../../components/pagination/Pagination'
import {LIMIT} from '../../constants/api'
import ListView from '../common/ListView'
import {useHistory} from 'react-router'
import {CUSTOMERS_URL} from '../../constants/urls'

const Customers = () => {
  const {push} = useHistory()

  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(true)
  const [offset, setOffset] = useState(0)
  const [query, setQuery] = useState('')
  const [total, setTotal] = useState()

  useEffect(() => {
    setLoading(true)
    fetchCustomers({query, offset})
      .then(({customers, total}) => {
        setCustomers(customers)
        setTotal(total)
      })
      .finally(() => setLoading(false))
  }, [query, offset])

  const toCustomer = id => push(`${CUSTOMERS_URL}/${id}`)

  return (
    <ListView heading={localise('nav.customers')}>
      <CustomersTable
        count={total}
        data={customers}
        handleSearch={setQuery}
        loading={loading}
        onRowClick={toCustomer}
        pagination={{
          onPageChange: page => setOffset(calculateOffset(page, LIMIT)),
          pageCount: total / LIMIT,
          offset,
          total,
        }}
      />
    </ListView>
  )
}

export default Customers
