import React from 'react'
import {func, string} from 'prop-types'
import {Form} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import {debounce} from 'lodash'

const propTypes = {
  className: string,
  defaultValue: string,
  handleSearch: func,
  label: string,
}

const defaultProps = {
  className: '',
  defaultValue: '',
  handleSearch: () => {},
  label: '',
}

const DEBOUNCE_TIME = 500

const SearchInput = ({className, defaultValue, handleSearch, label}) =>
  <div className={`search-input-container d-flex my-auto ${className}`}>
    <Form.Label className='my-auto text-nowrap mr-1 pl-2 font-15'>
      {label || `${localise('form.label.search')}:`}
    </Form.Label>
    <Form.Control
      className='search-input input-sm rounded-0'
      defaultValue={defaultValue}
      onChange={debounce(({target: {value}}) => handleSearch(value), DEBOUNCE_TIME)}
      type='text'
    />
  </div>

SearchInput.propTypes = propTypes
SearchInput.defaultProps = defaultProps

export default SearchInput
