import React from 'react'
import {array, bool, func, node, string} from 'prop-types'
import {Button, Col, Row} from 'react-bootstrap'
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'
import Separator from '../../components/separator/Separator'

const propTypes = {
  breadcrumbItems: array,
  buttons: node,
  children: node,
  className: string,
  hideButtons: bool,
  onCancel: func,
  onSave: func,
}

const defaultProps = {
  breadcrumbItems: [],
  buttons: null,
  children: null,
  className: '',
  hideButtons: false,
  onCancel: () => Console.dev('on cancel'),
  onSave: () => Console.dev('on save'),
}

const BreadCrumbsView = props => {
  const {
    breadcrumbItems,
    buttons,
    children,
    className,
    hideButtons,
    onCancel,
    onSave,
  } = props

  return (
    <Row noGutters className={`breadcrumbs-view-container ${className}`}>
      <Col>
        <Row>
          <Col xs='12' md='6'><BreadCrumbs breadcrumbItems={breadcrumbItems} /></Col>
          <Col xs='12' md='6'>
            {
              !hideButtons &&
              (buttons || (
                <div className='d-flex mt-2 mt-md-0'>
                  <Button variant='outline' className='font-13 mr-2 ml-md-auto' onClick={onCancel}>
                    {localise('buttonText.cancel')}
                  </Button>
                  <Button variant='secondary' className='font-13' onClick={onSave}>
                    {localise('buttonText.save')}
                  </Button>
                </div>
              ))
            }
          </Col>
        </Row>
        <Separator className='mt-2 mb-3' />
        <Row>
          <Col>
            {children}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

BreadCrumbsView.propTypes = propTypes
BreadCrumbsView.defaultProps = defaultProps

export default BreadCrumbsView