import React from 'react'
import {Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import Customer from '../views/customer/Customer'
import Customers from '../views/customer/Customers'

const CustomerRoutes = ({path}) =>
  <Switch>
    <RouteWithLayout exact path={path} component={Customers} />
    <RouteWithLayout exact path={`${path}/:slug`} component={Customer} />
  </Switch>

export default CustomerRoutes
